export default {
  setThematics(state, value) {
    state.thematics = value;
  },
  setCategories(state, value) {
    state.categories = value;
  },
  setThematic(state, value) {
    state.thematic = value;
  },
  setDefaultThematic(state) {
    state.thematic = JSON.parse(JSON.stringify(state.newThematic));
  },
};
