<template>
  <ButtonIcon
    color="pink"
    size="large"
    :text="$t('wizard.thematics.actions.add_categories')"
    class="is-responsive mt-2"
    icon="IconPlus"
  />
</template>
<script>
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";
export default {
  name: "ButtonAddCompanySector",
  components: { ButtonIcon },
};
</script>
