<template>
  <figure
    v-if="user.avatar && isImageFound"
    class="image pr-2"
    :class="{
      'is-96x96': size === 'big',
      'is-64x64': size === 'medium',
    }"
  >
    <img
      class="is-rounded rounded"
      :class="{
        'has-border-blue': hasBorderBlue,
      }"
      :src="user.avatar_thumb_cropped || user.avatar"
      :alt="imgAlt"
      :title="imgAlt"
      width="auto"
      height="auto"
      @error="handleImageError"
    />
  </figure>

  <figure
    v-else
    class="image is-rounded rounded is-flex is-justify-content-center is-align-items-center"
    :class="{
      'is-96x96 is-size-1': size === 'big',
      'is-64x64 is-size-3': size === 'medium',
      'has-border-blue': hasBorderBlue,
    }"
  >
    <IconUser class="has-fill-blue" />
  </figure>
</template>
<script>
export default {
  name: "UserAvatarBigSize",
  props: ["user", "imgAlt", "size", "hasBorderBlue"],

  data() {
    return {
      isImageFound: true,
    };
  },

  methods: {
    handleImageError() {
      this.isImageFound = false;
    },
  },
};
</script>
