export default () => ({
  newOrder: {
    thematic_id: null,
    locale: null,
    website_id: null,
    order_type: null,
    company: [],
    desired_content_length: null,
    order_texts_number: null,
    total_content_length: null,
    writer_level: null,
    desired_delivery_date: null,
    options: [],
    user_reference: "",
    type: null,
    cocoon_files: [],
    target_url_cocoon: "",
    starting_url_cocoon: "",
    ext_url_cocoon: "",
    favorite_writers: null,
    template_id: null,
    editable_template: true,
    is_created_by: null,
    assistance_requested_at: null,
    has_global_brief: true,
    brief_value: null,
    subscription_type_id: null,
    subscription_duration: null,
    subscription_discount: null,
  },

  orders: null,
  order: null,
  options: null,

  ordersConfig: {
    include: "",
    filters: {
      status: null,
      between: null,
      reference: null,
    },
  },

  orderConfig: {
    include: "options,texts,website,company",
  },

  orderTracking: null,
  orderComments: null,
  orderHasTemplate: false,
  activeOrdersTab: 0,

  orderCounts: {
    in_progress_orders: "00",
    urgent_orders: "00",
    new_orders: "0",
    all_orders: "00",
    to_be_assigned_orders: "00",
    in_correction_orders: "00",
    in_preparation_orders: "00",
    orders_has_application: "00",
    orders_with_seo_support: "00",
    orders_has_subscription: "00",
  },

  defaultFilters: {
    writer: {
      in_progress_orders: {
        application_has_been_approved: true,
        has_reserved_or_available_texts: true,
        has_in_progress_texts: true,
      },
      urgent_orders: {
        urgent_orders: true,
        has_reserved_or_available_texts: true,
      },
      new_orders: { not_applied_by: true },
      orders_has_subscription: {
        has_subscription: true,
      },
    },
    editorManager: {
      new_orders: {
        status: [4],
      },
      in_progress_orders: {
        status: [5, 6],
      },
      orders_has_application: {
        has_application: true,
        status: [4, 5, 6, 8],
      },
      to_be_assigned_orders: {
        orders_to_assign: true,
        status: [4, 5, 6, 8],
      },
      urgent_orders: {
        urgent_orders: true,
        status: [4, 5, 6, 8],
      },
      in_correction_orders: {
        has_text_in_correction: true,
        status_is_not: 7,
      },
      in_preparation_orders: {
        status: [1, 2, 3],
      },
      all_orders: {
        status: [4, 5, 6, 7, 8, 9, 10],
      },
      orders_with_seo_support: {
        status: [3, 4, 5, 6, 7],
        has_seo_support: true,
      },
      orders_has_subscription: {
        status: [3, 4, 5, 6, 7, 8, 9, 10],
        has_subscription: true,
      },
    },
  },

  orderBriefSaving: false,
  orderWithFailedPayment: null,

  payloadModels: {
    update: [
      "user_reference",
      "brief",
      "desired_content_length",
      "order_texts_number",
      "total_content_length",
      "writer_level",
      "order_type",
      "locale",
      "desired_delivery_date",
      "type",
      "monthly", // used only if type == 3
      "target_url_cocoon",
      "starting_url_cocoon",
      "ext_url_cocoon",
      "favorite_writers",
      "template_id",
      "editable_template",
      "has_global_brief",
      "subscription_type_id",
      "subscription_duration",
      "options",
      "thematic_id",
      "brief_value",
    ],
  },
});
