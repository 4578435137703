export default () => ({
  writers: null,
  writerStats: null,
  candidates: null,
  candidate: null,
  applicants: null,
  compatibleWriters: null,
  locales: {
    fr: { value: "fr", id: 1 },
    en: { value: "en", id: 2 },
    es: { value: "es", id: 3 },
  },
  writerPreferences: {
    subjects: [],
    locales: [],
    subjectsId: [],
  },
  preferencesFormValue: {},
  writersConfig: {
    filters: {},
  },
  candidatesConfig: {},
  favoriteWriters: "null",
  favoriteWritersCount: null,
  myWriters: null,
});
