<template>
  <div class="is-flex is-align-items-center">
    <figure class="image is-16x16">
      <IconCircle class="icon-size-1_3" :color="statusColor" />
    </figure>
    <p class="body1 ml-2 has-text-grey-darker">
      {{ statusName }}
    </p>
  </div>
</template>
<script>
export default {
  name: "subscription-status-order-card",
  props: ["status", "options"],

  computed: {
    statusColor() {
      return this.$t(`${this.options}.${this.status}.color`);
    },

    statusName() {
      return this.$t(`${this.options}.${this.status}.name`);
    },
  },
};
</script>
