<template>
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 9.75C2 9.41848 2.1317 9.10054 2.36612 8.86612C2.60054 8.6317 2.91848 8.5 3.25 8.5H10"
      class="iconPath"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.25 1H10V11H3.25C2.91848 11 2.60054 10.8683 2.36612 10.6339C2.1317 10.3995 2 10.0815 2 9.75V2.25C2 1.91848 2.1317 1.60054 2.36612 1.36612C2.60054 1.1317 2.91848 1 3.25 1V1Z"
      class="iconPath"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBook",
};
</script>
