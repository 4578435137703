import store from "@/store";

const websiteViewMiddleware = (to, from, next) => {
  const userHasAllowedRole =
    store.getters["auth/client"] ||
    store.getters["auth/editorManager"] ||
    store.getters["auth/admin"];
  const homepagePath = store.getters["auth/homepage"];

  if (userHasAllowedRole) {
    next();
  } else {
    window.location.href = homepagePath;
  }
};

export default [
  {
    path: "websites",
    beforeEnter: websiteViewMiddleware,
    component: () => import("@/views/Site/Sites.vue"),
  },
  {
    path: "website/:reference",
    beforeEnter: websiteViewMiddleware,
    component: () => import("@/views/Site/Site.vue"),
  },
];
