<template>
  <b-table
    ref="ordersTable"
    checkable
    paginated
    backend-filtering
    backend-pagination
    backend-sorting
    class="radius"
    :aria-next-label="$t('dashboard.table.next_page')"
    :aria-previous-label="$t('dashboard.table.previous_page')"
    :aria-page-label="$t('dashboard.table.page')"
    ::aria-current-label="$t('dashboard.table.current_page')"
    :data="orders.data"
    :checked-rows.sync="checkedRowsValue"
    :checkbox-position="checkboxPosition"
    :default-sort="defaultSort"
    :per-page="orders.meta.per_page"
    :total="orders.meta.total"
    :debounce-search="300"
    :row-class="orderRowClass"
    :loading="loading"
    :current-page="orders.meta.current_page"
    @page-change="onPageChange"
    @sort="onSort"
    @filters-change="onFilterChange"
  >
    <b-table-column
      v-slot="props"
      field="reference"
      sortable
      width="8%"
      class="body2 has-text-red"
      :label="$t('order.table.headers.reference')"
      :searchable="true"
    >
      <p class="body1 has-text-grey-darker">
        {{ props.row.reference }}
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="company.name"
      searchable
      sortable
      class="body2 has-text-red"
      width="10%"
      :label="$t('order.table.headers.company_name')"
    >
      <p class="body1 has-text-grey-darker">
        {{ props.row.company?.name }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="admin || editorManager || corrector"
      v-slot="props"
      field="writers"
      class="body2 has-text-red"
      width="5%"
      :label="$t('order.table.headers.writers')"
    >
      <UsersAvatars
        v-if="props.row.writers?.length"
        has-blink-animation
        borderColor="white"
        :editors-list="props.row.writers"
        :onlines="props.row.users_under_editing"
      />
      <p
        v-else-if="Object.keys(props.row.users_under_editing)?.length"
        class="body1 has-text-green"
      >
        <b-tooltip
          active
          multilined
          position="is-bottom"
          :label="
            Object.keys(props.row.users_under_editing).length === 1
              ? '1 ' + $ts('order.notifications.active_writer').toLowerCase()
              : `${Object.keys(props.row.users_under_editing).length} ` +
                $tp('order.notifications.active_writer').toLowerCase()
          "
        >
          <IconEdit class="mt-1" />
        </b-tooltip>
      </p>
    </b-table-column>

    <b-table-column
      field="website.domain"
      v-slot="props"
      searchable
      sortable
      class="body2 has-text-red"
      width="15%"
      :label="$t('order.table.headers.website_domain')"
    >
      <p class="body1 has-text-grey-darker">
        {{ props.row.website.domain }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="editorManager"
      v-slot="props"
      field="writer_level"
      sortable
      class="body2 has-text-red"
      width="15%"
      :label="$t('order.table.headers.editor_manager.writer_level')"
    >
      <p v-if="props.row.writer_level" class="body1 has-text-grey-darker">
        {{
          $t(`text.variants.writing_level_recruiter.${props.row.writer_level}`)
        }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="editorManager || writer || corrector"
      v-slot="props"
      field="order_texts_attribued_number"
      width="8%"
      :label="$t('order.table.headers.order_texts_attribued_number')"
    >
      <p
        v-if="
          props.row.order_texts_attribued_number >= 0 &&
          props.row.order_texts_number
        "
        class="body1 has-text-grey-darker"
      >
        {{ props.row.order_texts_attribued_number }}/
        {{ props.row.order_texts_number }}
      </p>
      <p v-else class="body1 has-text-grey-darker"></p>
    </b-table-column>

    <b-table-column
      v-if="editorManager"
      v-slot="props"
      field="written_content_percentage"
      width="8%"
      :label="$t('order.table.headers.written_content_percentage')"
    >
      <p class="body1 has-text-grey-darker">
        {{ props.row.written_content_percentage }}%
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="order_type"
      class="body2 has-text-red"
      :label="$t('order.table.headers.order_type')"
    >
      <p class="body1 has-text-grey-darker">
        <span v-if="props.row.order_texts_number">
          {{ props.row.order_texts_number }}
        </span>
        <span v-if="props.row.order_type">
          {{ $t(`text.variants.type.${props.row.order_type}`) }}
        </span>
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="locale"
      class="body2 has-text-red"
      :label="$t('order.table.headers.locale')"
    >
      <p class="body1 has-text-grey-darker">
        {{ $t(`locale.${props.row.locale}`) }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="writer"
      v-slot="props"
      field="thematic.name"
      class="body2 has-text-red"
      :label="$t('order.table.headers.thematic_name')"
    >
      <p class="body1 has-text-grey-darker">
        {{ props.row.thematic.name }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="writer"
      v-slot="props"
      field="writer_level"
      class="body2 has-text-red"
      :label="$t('order.table.headers.writer.writer_level')"
    >
      <p class="body1 has-text-grey-darker">
        {{ $t(`text.variants.writing_level.${props.row.writer_level}`) }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="corrector"
      v-slot="props"
      width="10%"
      :field="
        desired_content_length
          ? 'desired_content_length'
          : 'total_content_length'
      "
      :label="$t('order.table.headers.desired_content_length')"
    >
      <p class="body1 has-text-grey-darker">
        {{
          props.row.desired_content_length
            ? $t("order.details.desired_content_length_per_text", {
                count: props.row.desired_content_length,
              })
            : $t("order.details.total_content_length_per_text", {
                count: props.row.total_content_length,
              })
        }}
      </p>
    </b-table-column>

    <b-table-column
      v-if="writer"
      v-slot="props"
      field="desired_delivery_date"
      width="10%"
      sortable
      :label="$t('order.table.headers.desired_delivery_date')"
    >
      <div v-if="props.row[orderDeliveryDateKey]">
        <template v-if="props.row.status < ORDER_STATUSES.SENT">
          <p
            v-if="isDateGreater(props.row[orderDeliveryDateKey], 3)"
            class="body1 has-text-danger-dark"
          >
            {{ props.row[orderDeliveryDateKey] | toDate }}
          </p>
          <p
            v-else-if="isDateGreater(props.row[orderDeliveryDateKey], 8)"
            class="body1 has-text-warning-dark"
          >
            {{ props.row[orderDeliveryDateKey] | toDate }}
          </p>
          <p v-else class="body1 has-text-grey-darker">
            {{ props.row[orderDeliveryDateKey] | toDate }}
          </p>
        </template>
        <p v-else class="body1 has-text-grey-darker">
          {{ props.row[orderDeliveryDateKey] | toDate }}
        </p>
      </div>
    </b-table-column>

    <b-table-column
      v-else
      v-slot="props"
      field="desired_delivery_date"
      width="10%"
      sortable
      :label="$t('order.table.headers.desired_delivery_date')"
    >
      <div v-if="props.row.desired_delivery_date">
        <template v-if="props.row.status < ORDER_STATUSES.SENT">
          <p
            v-if="isDateGreater(props.row.desired_delivery_date, 3)"
            class="body1 has-text-danger-dark"
          >
            {{ props.row.desired_delivery_date | toDate }}
          </p>
          <p
            v-else-if="isDateGreater(props.row.desired_delivery_date, 8)"
            class="body1 has-text-warning-dark"
          >
            {{ props.row.desired_delivery_date | toDate }}
          </p>
          <p v-else class="body1 has-text-grey-darker">
            {{ props.row.desired_delivery_date | toDate }}
          </p>
        </template>
        <p v-else class="body1 has-text-grey-darker">
          {{ props.row.desired_delivery_date | toDate }}
        </p>
      </div>
    </b-table-column>

    <b-table-column
      v-slot="props"
      sortable
      field="status"
      :label="$t('order.table.headers.status')"
    >
      <StatusOrderCard
        options="order.variants.status"
        :status="props.row.status"
      />
      <StatusOrderCard
        v-if="
          editorManager &&
          props.row.status == ORDER_STATUSES.AWAITING_CLIENT &&
          [
            ORDER_SUBJECT_STATUSES.PENDING,
            ORDER_SUBJECT_STATUSES.TO_VALIDATE,
          ].includes(+props.row.subjects_status)
        "
        class="mb-1"
        options="orders.subjects_status"
        :status="props.row.subjects_status"
      />
    </b-table-column>

    <b-table-column
      v-if="writer || candidate"
      class="is-flex is-flex-wrap-wrap p-0"
      width="5%"
      v-slot="props"
    >
      <ButtonRouterLink
        v-if="
          props.row.texts.filter(
            (el) => el.writer_id === user.id && parseInt(el.status) === 2
          ).length >= 1
        "
        size="small"
        icon="IconEdit"
        color="pink"
        class="is-fullwidth"
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
        :text="$t('common.actions.write')"
      />

      <ButtonRouterLink
        v-else-if="
          props.row.texts.filter(
            (el) => !el.writer_id && parseInt(el.status) === 1
          ).length >= 1
        "
        size="small"
        icon="IconEdit"
        color="blue"
        class="is-fullwidth"
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
        :text="$t('common.actions.write')"
      />

      <ButtonRouterLink
        v-else
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
        size="small"
        :text="$t('common.actions.view')"
        icon="IconEye"
        color="blue"
        class="is-fullwidth"
      />
    </b-table-column>

    <b-table-column
      v-if="corrector && !editorManager"
      class="is-flex is-flex-wrap-wrap p-0"
      width="5%"
      v-slot="props"
    >
      <ButtonIcon
        v-if="
          props.row.texts.filter(
            (el) => el.corrector_id === user.id && parseInt(el.status) === 3
          ).length > 0
        "
        color="pink"
        size="small"
        icon="IconCheck"
        class="is-fullwidth"
        :text="$t('text.actions.correct')"
        @click-button="
          seeOrderDetails(props.row.website_id, props.row.reference)
        "
      />
      <ButtonRouterLink
        v-else-if="
          props.row.texts.filter(
            (el) => !el.corrector_id && parseInt(el.status) === 3
          ).length > 0
        "
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
        size="small"
        icon="IconCheck"
        color="blue"
        class="is-fullwidth"
        :text="$t('text.actions.correct')"
      />
      <ButtonRouterLink
        v-else
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
        size="small"
        :text="$t('common.actions.view')"
        icon="IconEye"
        color="blue"
        class="is-fullwidth"
      />
    </b-table-column>

    <b-table-column
      v-if="editorManager"
      class="is-flex is-flex-wrap-wrap p-0"
      width="2%"
      v-slot="props"
    >
      <template
        v-if="
          (parseInt(props.row.status) === ORDER_STATUSES.DRAFT ||
            parseInt(props.row.status) === ORDER_STATUSES.AWAITING_PAYMENT) &&
          props.row.is_created_by === user.id
        "
      >
        <ButtonRouterLink
          v-if="props.row.reference && props.row.website.has_brief"
          :link="
            props.row.user &&
            `${homepage}/user/${props.row.user.reference}/website/${props.row.website_id}/new-order/${props.row.reference}`
          "
          size="small"
          :text="$t('common.actions.modify')"
          icon="IconOrder"
          color="pink"
          class="is-fullwidth"
        />
        <ButtonRouterLink
          v-else-if="props.row.reference"
          :link="
            props.row.user &&
            `${homepage}/user/${props.row.user.reference}/new-website/${props.row.website_id}/order/${props.row.reference}`
          "
          size="small"
          :text="$t('common.actions.modify')"
          icon="IconGlobe"
          color="pink"
          class="is-fullwidth"
        />
        <ButtonRouterLink
          v-else
          :link="
            props.row.user &&
            `${homepage}/user/${props.row.user.reference}/new-website/${props.row.website_id}`
          "
          size="small"
          :text="$t('common.actions.modify')"
          icon="IconGlobe"
          color="pink"
          class="is-fullwidth"
        />
      </template>
      <template
        v-else-if="
          parseInt(props.row.status) === ORDER_STATUSES.AWAITING_CLIENT &&
          ((props.row.type && props.row.type === 2) ||
            (props.row.website.company_id &&
              props.row.website.company_id === 1) ||
            parseInt(props.row.subjects_status) ===
              ORDER_SUBJECT_STATUSES.PENDING)
        "
      >
        <ButtonRouterLink
          v-if="props.row.previous_subscription_cycle_texts_count"
          :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
          size="small"
          :text="$t('common.actions.view')"
          icon="IconEye"
          color="blue"
          class="is-fullwidth is-block mb-2"
        />

        <ButtonRouterLink
          :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
          size="small"
          :text="$t('order.actions.complete')"
          icon="IconEdit"
          color="pink"
          class="is-fullwidth"
        />
      </template>
      <ButtonRouterLink
        v-else-if="parseInt(props.row.status) === ORDER_STATUSES.VALIDATING"
        size="small"
        icon="IconCheck"
        color="pink"
        class="is-fullwidth"
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/brief?originUrl=${originUrl}`"
        :text="$t('common.actions.verify')"
      />
      <ButtonRouterLink
        v-else
        :link="`${homepage}/website/${props.row.website_id}/order/${props.row.reference}/texts?originUrl=${originUrl}`"
        size="small"
        :text="$t('common.actions.view')"
        icon="IconEye"
        color="blue"
        class="is-fullwidth"
      />
    </b-table-column>

    <b-table-column
      v-if="editorManager || admin"
      class="is-flex is-flex-wrap-wrap p-0"
      width="2%"
      v-slot="props"
    >
      <button
        class="has-border-pink button has-hover-bottom mx-1 is-small is-fullwidth has-text-pink is-inverted is-flex is-align-items-center"
        @click.prevent="
          duplicateOrder(
            props.row.user.reference,
            props.row.website_id,
            props.row.reference
          )
        "
      >
        <IconCopy class="has-text-pink" />
      </button>
    </b-table-column>

    <template #bottom-left>
      <b-select v-model="perPage">
        <option value="20">
          {{
            $t("common.collection.pagination.per_page_option", { count: 20 })
          }}
        </option>
        <option value="50">
          {{
            $t("common.collection.pagination.per_page_option", { count: 50 })
          }}
        </option>
        <option value="100">
          {{
            $t("common.collection.pagination.per_page_option", { count: 100 })
          }}
        </option>
      </b-select>
    </template>
  </b-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ORDER_SUBJECT_STATUSES, ORDER_STATUSES } from "@/utils/constants";
import { percentage, orderDeliveryDateKey } from "@/utils/index";
import StatusOrderCard from "@/components/Model/Order/StatusOrderCard";
import UsersAvatars from "@/components/Model/User/UsersAvatars";
import ButtonRouterLink from "@/components/UI/buttons/ButtonRouterLink";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";

export default {
  name: "OrdersTable",

  components: {
    ButtonRouterLink,
    ButtonIcon,
    StatusOrderCard,
    UsersAvatars,
  },

  props: {
    orders: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    checkedRows: {
      type: Array,
      default: () => [],
    },

    checkboxPosition: {
      type: String,
      default: "left",
    },

    defaultSort: {
      default() {
        return ["created_at", "desc"];
      },
    },

    activePage: {
      type: Number,
      default: 1,
    },

    activePerPage: {
      type: Number,
      default: 20,
    },

    defaultFilters: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      defaultFiltersConfigured: false,
      ORDER_SUBJECT_STATUSES,
      ORDER_STATUSES,
    };
  },

  mounted() {
    if (this.defaultFilters && Object.keys(this.defaultFilters).length) {
      this.$refs.ordersTable.filters = this.defaultFilters;
      this.defaultFiltersConfigured = true;
    }
  },

  computed: {
    ...mapState("auth", ["user", "homepage"]),
    ...mapState("order", ["order"]),
    ...mapGetters("auth", [
      "admin",
      "writer",
      "client",
      "editorManager",
      "corrector",
      "candidate",
    ]),
    orderDeliveryDateKey,

    checkedRowsValue: {
      get() {
        return this.checkedRows;
      },
      set(value) {
        this.$emit("update:checkedRows", value);
      },
    },

    perPage: {
      get() {
        return this.activePerPage;
      },
      set(value) {
        this.$emit("per-page-change", value);
        this.$router.replace({
          query: {
            ...this.$route.query,
            per_page: parseInt(value),
          },
        });
      },
    },

    originUrl() {
      const originUrl = this.$router.resolve({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.activePage,
          per_page: this.activePerPage,
        },
      }).href;

      return encodeURIComponent(originUrl);
    },
  },

  methods: {
    percentage() {
      return percentage();
    },
    ...mapActions("order", ["getDuplicateOrder"]),

    isDateGreater(d1, days) {
      d1 = new Date(d1);
      let today = new Date();

      return today.setDate(today.getDate() + (days || 0)) > d1;
    },

    seeOrderDetails(webId, ref) {
      this.$router.push({
        path: `${this.homepage}/website/${webId}/order/${ref}/texts?originUrl=${this.originUrl}`,
      });
    },

    duplicateOrder(userRef, websiteId, orderRef) {
      this.getDuplicateOrder(orderRef)
        .then((response) => {
          this.$router.push(
            `${this.homepage}/user/${userRef}/website/${websiteId}/new-order/${response.data.order.reference}`
          );
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },

    onPageChange(page) {
      this.$emit("page-change", page);
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: page,
        },
      });
    },

    onSort(field, order) {
      this.$emit("sort", {
        field,
        order,
      });
    },

    onFilterChange(filter) {
      if (this.defaultFiltersConfigured) {
        this.defaultFiltersConfigured = false;
        return;
      }

      this.$emit("filters-change", filter);
    },

    orderRowClass(row) {
      if (row.texts_last_return_histories?.length) {
        if (
          row.texts_last_return_histories.some(this.textHasFeedbackByGivenRole)
        ) {
          return "has-text-feedback-by-given-role";
        }
      }

      if (row.is_internal_order) {
        return "is-row-internal-order";
      }

      return "";
    },

    textHasFeedbackByGivenRole(last_return_history) {
      return last_return_history.resender_roles.some((role) =>
        ["client", "corrector"].includes(role)
      );
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .is-row-internal-order {
    background-color: #dbdce5;
  }

  .has-text-feedback-by-given-role {
    background-color: #fef2f5;
  }
}
</style>
