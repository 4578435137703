import { sanitizePayload } from "@/utils/payload";
import { GET, POST, PUT, PATCH } from "@/config/backend";

function prepareParams(config) {
  return new Promise((resolve) => {
    let field = config.sort;

    if (config.sortDirection === "desc") {
      field = "-" + field;
    }

    let params = {
      page: config.page,
      include: config.include,
      sort: field,
      per_page: config.per_page,
    };

    if (config.filters) {
      for (const [key, value] of Object.entries(config.filters)) {
        params[`filter[${key}]`] = value;
      }
    }

    if (config.resource_fields) {
      params.resource_fields = config.resource_fields;
    }

    resolve(params);
  });
}
export default {
  async getOrderTexts(context, reference) {
    const params = await prepareParams(context.state.textsConfig);
    const response = await GET(`orders/${reference}/texts`, { params });
    context.commit("setOrderTexts", response.data.orderTexts);
    return response;
  },

  async getOrderText(context, data) {
    const params = await prepareParams(context.state.textConfig);
    const response = await GET(
      `orders/${data.orderReference}/texts/${data.textReference}`,
      { params }
    );
    context.commit("setOrderText", response.data.orderText);
    context.commit(
      "setTextKeywords",
      response.data.orderText.keywords_correction ??
        response.data.orderText.keywords ??
        ""
    );
    return response;
  },

  async getWebsiteValidatedTextsCount(context, websiteId) {
    const response = await GET(`orders/website/${websiteId}/texts/count`);
    context.commit("setValidatedTextsCount", response.data.orderTextsCount);
    return response;
  },

  async getWebsiteValidatedTexts(context, data) {
    const params = await prepareParams(context.state.textsConfig);
    const response = await GET(`orders/website/${data.websiteId}/texts`, {
      params,
    });
    context.commit("setOrderTexts", response.data.orderTexts);
    context.commit("setValidatedTextsCount", response.data.orderTexts.total);
    return response;
  },

  async sendOrderTexts({ state }, orderReference) {
    return await POST(
      `orders/${orderReference}/texts`,
      state.orderTexts["texts"]
    );
  },

  async getTextTracking(context, reference) {
    const response = await GET(`texts/${reference}/tracking`);
    context.commit("setTextTracking", response.data.tracking.data);
    return response;
  },

  async getTextComments(context, reference) {
    const response = await GET(`texts/${reference}/comments`);
    context.commit("setTextComments", response.data.comments);
    return response;
  },

  async sendTextsFeedBack(context, data) {
    const response = await POST(`texts/${data.reference}/feedbacks`, {
      type: data.type,
      message: data.message,
    });
    context.commit("setNewOrder", response.data.order);
    return response;
  },

  async sendTextComment(context, data) {
    return await PUT(`texts/${data.reference}/comment`, {
      correction: data.comment,
    });
  },

  async sendWriterComment(context, data) {
    return await PUT(`texts/${data.reference}/comment/save`, data.comment);
  },

  async sendBlockComment(context, data) {
    const response = await PUT(`texts/blocks/${data.block_id}/comment`, data);
    context.commit("setTextBlockComment", response.data.comment);
    return response;
  },

  async updateTextStatus(context, reference) {
    return await GET(`texts/${reference}/validate`);
  },

  async patchTextStatus(context, { reference, status }) {
    return await PATCH(`texts/${reference}/status`, { status });
  },

  async downloadSelectedTexts(context, data) {
    return await POST(
      `texts/downloadBulk`,
      {
        references: data.references,
        ext: data.extension,
        orderReference: data.orderReference,
      },
      {
        responseType: "arraybuffer",
      }
    );
  },

  async rollbackTextsStatus(context, reference) {
    return await GET(`texts/${reference}/transition-to-in-progress`);
  },

  async storeContent({ state, commit }, data) {
    const response = await PUT(
      `/orders/${data.orderReference}/texts/${data.textReference}/save`,
      sanitizePayload(data, state.payloadModels.updateContent)
    );
    commit("setOrderText", response.data.orderText);
    return response;
  },

  async sendCorrectedKeywords(context, data) {
    const response = await PUT(
      `texts/${data.reference}/keywords/correction/save`,
      {
        keywords_correction: data.keywords.keywords_correction,
        type: data.type,
      }
    );
    context.commit("setOrderText", response.data.orderText);
    context.commit(
      "setTextKeywords",
      response.data.orderText.keywords_correction ??
        response.data.orderText.keywords ??
        ""
    );
    return response;
  },

  async unaffectTextWriter(context, reference) {
    return await PUT(`texts/${reference}/unaffect-writer`);
  },

  async unaffectTextCorrector(context, reference) {
    return await PUT(`texts/${reference}/unaffect-corrector`);
  },

  async sendCorrectedSubjects(context, data) {
    const response = await PUT(
      `texts/${data.reference}/subjects/correction/save`,
      data.subjects
    );
    context.commit("setOrderText", response.data.orderText);
    context.commit(
      "setTextSubjects",
      response.data.orderText.keywords_correction ??
        response.data.orderText.subjects ??
        ""
    );
    return response;
  },

  async getDownloadText(context, data) {
    return await GET(`texts/${data.reference}/download/${data.ext}`, {
      responseType: "arraybuffer",
    });
  },

  async getDownloadOrderTexts(context, data) {
    return await GET(
      `orders/${data.orderReference}/texts/download/${data.extension}`,
      {
        responseType: "arraybuffer",
      }
    );
  },

  async updateSignature(context, { reference, data }) {
    return await PUT(`texts/${reference}/signature`, data);
  },

  async resetTemplate(context, reference) {
    return await PUT(`texts/${reference}/reset`);
  },
};
