<template>
  <span>{{ ($store.state.credit.balance * 1) | toCurrency }}</span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CreditBalance",

  beforeMount() {
    this.getBalance(this.$store.state.auth.user.company_id);
  },

  methods: {
    ...mapActions("credit", ["getBalance"]),
  },
};
</script>
