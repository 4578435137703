<template>
  <div>
    <OrderBriefArticle
      v-if="field.isEditableFormat && !isEditing"
      :isEditing="isEditing"
      :field="field"
      :value="field.value"
      @start-editing="isEditing = true"
    />
    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <div class="isSmallField mt-2" v-if="isSmallField">
        <b-field :label="field.label ? field.label : ''" />
      </div>

      <div class="columns is-multiline">
        <b-field
          v-for="option in options"
          :key="option.id"
          class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
        >
          <input
            v-if="option.id === 4 || option.id === 5"
            v-model="selected"
            type="checkbox"
            class="labelForCheck"
            :id="`options-${option.id}`"
            :value="option.id"
          />
          <label
            v-if="option.id === 4 || option.id === 5"
            class="b-checkbox checkbox button is-fullwidth"
            :for="`options-${option.id}`"
            >{{ option.name }}
          </label>
        </b-field>
      </div>

      <CardValidationField
        v-if="!selected || (selected && selected.length === 0)"
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "CandidateTextOptionsFormItem",
  props: ["field", "indexStep", "indexField", "isNextStep", "isSmallField"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : [],
    };
  },
  created() {
    this.getOptions()
      .then(() => {})
      .catch((e) => {
        this.$store.dispatch("fireError", e);
      });
  },
  methods: {
    ...mapActions("order", ["getOptions"]),
  },
  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
      });
    },
  },
  computed: {
    ...mapState("order", ["options"]),
  },
};
</script>
