<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value ? $t(`${path}.${field.value}`) : null"
        @start-editing="isEditing = true"
      />
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <div class="isSmallField mt-2" v-if="isSmallField">
        <b-field :label="field.label ? field.label : ''">
          <b-select v-model="selected" class="is-fullwidth">
            <option v-for="option in options" :key="option" :value="option">
              {{ $t(`${path}.${option}`) }}
            </option>
          </b-select>
          <b-button
            v-if="field.isEditable && field.isUserView"
            class="is-small has-text-blue has-border-none"
            @click.prevent="saveComponent"
          >
            <IconSave class="iconPen" />
          </b-button>
        </b-field>
        <CardValidationField
          :field="field.isValid"
          :is-next-step="isNextStep"
          :message="$t('wizard.message.required')"
        />
      </div>
      <div v-else>
        <div class="columns is-multiline mt-2">
          <b-field
            v-for="option in options"
            :key="option"
            class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
          >
            <b-radio-button
              v-model="selected"
              :native-value="option"
              type="is-pink-light"
            >
              {{ $t(`${path}.${option}`) }}
            </b-radio-button>
          </b-field>
        </div>
        <CardValidationField
          :field="field.isValid"
          :is-next-step="isNextStep"
          :message="$t('wizard.message.required')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "TargetSelectItem",
  props: ["field", "indexStep", "indexField", "isSmallField", "isNextStep"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? `${this.field.value}` : null,
      path: this.field.path ? `${this.field.path}` : null,
      options: Object.keys(this.$t(this.field.path)),
    };
  },

  methods: {
    saveComponent() {
      this.isEditing = false;

      if (this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          model: this.field.model,
          value: this.selected ? this.selected : null,
        });
      }
    },
  },

  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        model: this.field.model,
        value: newValue,
      });
    },
  },
};
</script>
