import { GET } from "@/config/backend";

const getColor = (competitionLevelIndex, isUsed) => {
  const GREEN_THRESHOLD = 0.5;
  const ORANGE_THRESHOLD = 0.8;

  if (isUsed) {
    return "grey";
  }

  if (competitionLevelIndex >= 0 && competitionLevelIndex <= GREEN_THRESHOLD) {
    return "green";
  } else if (
    competitionLevelIndex > GREEN_THRESHOLD &&
    competitionLevelIndex <= ORANGE_THRESHOLD
  ) {
    return "orange-light";
  } else if (
    competitionLevelIndex > ORANGE_THRESHOLD &&
    competitionLevelIndex <= 1
  ) {
    return "red";
  }

  return null;
};

export default {
  async getRanxplorerRelatedKeywords({ dispatch, commit }, reference) {
    commit("resetRanxplorerRelatedKeywords");

    const response = await GET(`ranxplorer/${reference}/keywords`);
    dispatch("setKeywordWords", response.data.guide);
    return response;
  },

  async getRanxplorerKeywordsAnalyze({ dispatch }, reference) {
    const response = await GET(`ranxplorer/${reference}/keywordsAnalyze`);
    dispatch("setKeywordWords", response.data.guide);
    return response;
  },

  setKeywordWords(context, response) {
    if (response.related_keywords != []) {
      context.commit("setRanxplorerRelatedKeywords", response.related_keywords);
      context.dispatch("addColorKeywordWords");
    }
  },

  addColorKeywordWords(context) {
    if (context.state.ranxplorerRelatedKeywords) {
      let relatedKeywords = [];
      let competitionLevelIndex = null;

      context.state.ranxplorerRelatedKeywords.forEach((keyword) => {
        competitionLevelIndex = keyword.comp_level_index;

        if (competitionLevelIndex > 1) {
          competitionLevelIndex = competitionLevelIndex / 100;
        }

        let color = getColor(competitionLevelIndex, keyword.used);

        relatedKeywords.push({
          keyword: keyword.keyword,
          color: color,
        });
      });

      if (relatedKeywords) {
        context.commit("setRanxplorerRelatedKeywords", relatedKeywords);
      }
    }
  },

  async getRanxplorerRelatedQuestions({ dispatch, commit }, reference) {
    commit("resetRanxplorerRelatedQuestions");
    const response = await GET(`ranxplorer/${reference}/questions`);
    dispatch("setQuestions", response.data.guide);
    return response;
  },

  async getRanxplorerQuestionsAnalyze({ dispatch }, reference) {
    const response = await GET(`ranxplorer/${reference}/questionsAnalyze`);
    dispatch("setQuestions", response.data.guide);
    return response;
  },

  setQuestions(context, response) {
    if (response.related_questions != []) {
      context.commit(
        "setRanxplorerRelatedQuestions",
        response.related_questions
      );
      context.dispatch("addColorQuestions");
    }
  },

  addColorQuestions(context) {
    if (context.state.ranxplorerRelatedQuestions) {
      let relatedQuestions = [];
      let competitionLevelIndex = null;

      context.state.ranxplorerRelatedQuestions.forEach((question) => {
        competitionLevelIndex = question.comp_level_index;

        if (competitionLevelIndex > 1) {
          competitionLevelIndex = competitionLevelIndex / 100;
        }

        let color = getColor(competitionLevelIndex, question.used);

        relatedQuestions.push({
          question: question.question,
          color: color,
        });
      });

      if (relatedQuestions) {
        context.commit("setRanxplorerRelatedQuestions", relatedQuestions);
      }
    }
  },
};
