export default {
  setUser(state, data) {
    localStorage.setItem("user", JSON.stringify(data));
    state.user = data;
    let roles = [];

    if (state.user.roles) {
      state.user.roles.forEach((element) => {
        element.name
          ? roles.push(element.name)
          : element
          ? roles.push(element)
          : null;
      });

      state.roles = roles;
    }
  },
  setToken(state, token) {
    localStorage.setItem("token", token);
    state.token = token;
  },
  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
  },
  setTitle(state, data) {
    state.title = data;
  },
  setHomepage(state) {
    let mainRoleName = state.user.roles[0];

    if (typeof state.user.roles[0] === "object") {
      mainRoleName = state.user.roles.reduce((prev, current) => {
        return prev.level > current.level ? prev : current;
      }).name;
    }

    switch (mainRoleName) {
      case "admin":
        state.homepage = "/admin";
        break;
      case "corrector":
        state.homepage = "/corrector";
        break;
      case "editor-manager":
        state.homepage = "/editor";
        break;
      case "writer":
        state.homepage = "/writer";
        break;
      case "candidate":
        state.homepage = "/candidate";
        break;
      case "customer":
        state.homepage = "/customer";
        break;
      default:
        state.homepage = "/customer";
        break;
    }
  },
  setUserProfile(state, data) {
    state.user = data;
    localStorage.setItem("user", JSON.stringify(state.user));
    localStorage.setItem("token", state.token);
  },
  setRedirectRoute(state, route) {
    state.redirectRouteAfterLogin = route;
  },
};
