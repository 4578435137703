export default () => ({
  invoices: null,
  invoice: [],
  monthlyInvoices: null,
  unpaidMonthlyInvoice: null,
  monthlyInvoicesConfig: {
    include: "",
    sort: "id",
    sortDirection: "desc",
    page: 1,
    filters: "",
    per_page: 100,
  },

  invoicesConfig: {
    include: "",
    sort: "",
    sortDirection: "",
    page: 1,
    filters: "",
    per_page: 100,
  },
  orderPaymentsConfig: {
    page: 1,
    per_page: 100,
  },
  orderPayments: null,
});
