<template>
  <div>
    <div class="isSmallField mt-2" v-if="isSmallField">
      <b-field :label="field.label ? field.label : ''">
        <b-select v-model="selected" class="is-fullwidth">
          <option
            v-for="(option, index) in templates"
            :key="index"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
    <div v-else>
      <div class="columns is-multiline mt-2">
        <b-field
          v-for="(option, index) in templates"
          :key="index"
          class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
        >
          <b-radio-button
            v-model="selected"
            :native-value="option.id"
            type="is-pink-light"
          >
            {{ option.name }}
          </b-radio-button>
        </b-field>
      </div>
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import { mapState } from "vuex";

export default {
  name: "template-skribix-select",
  props: ["field", "indexStep", "indexField", "isSmallField", "isNextStep"],
  components: { CardValidationField },
  data() {
    return {
      selected: this.field.value ? this.field.value : null,
      hasCustomTemplate: false,
    };
  },
  created() {
    this.hasCustomTemplate = true;
  },
  computed: {
    ...mapState("template", ["templates"]),
    options() {
      return Object.keys(this.$t("wizard.template.skribix")).length;
    },
  },
  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
      });
    },
  },
};
</script>
