import { sanitizePayload } from "@/utils/payload";
import { GET, POST, DELETE, PUT, PATCH } from "@/config/backend";

function prepareParams(config) {
  return new Promise((resolve) => {
    let params = {};

    if (config.page) {
      params.page = config.page;
    }

    if (config.include) {
      params.include = config.include;
    }

    if (config.with) {
      params.with = config.with;
    }

    if (config.resource_fields) {
      params.resource_fields = config.resource_fields;
    }

    if (config.sort) {
      const sortField =
        config.sortDirection === "desc" ? `-${config.sort}` : config.sort;

      params.sort = sortField;
    }

    if (config.per_page) {
      params.per_page = config.per_page;
    }

    if (config.filters) {
      for (const [key, value] of Object.entries(config.filters)) {
        params[`filter[${key}]`] = value;
      }
    }

    resolve(params);
  });
}

function prepareUserParams(config) {
  return new Promise((resolve) => {
    let params = {
      include: config.include,
    };

    resolve(params);
  });
}

export default {
  async getUsersClients({ commit, state }) {
    const params = await prepareParams(state.usersConfig);
    const response = await GET(`/users/clients`, { params });
    commit("setUsers", response.data.clients.data);
    commit("setUsersCollection", response.data.clients);
    return response;
  },

  async getUsersWriters({ commit, state }) {
    const params = await prepareParams(state.usersConfig);
    const response = await GET(`/users/writers`, { params });
    commit("setUsers", response.data.writers.data);
    commit("setUsersCollection", response.data.writers);
    return response;
  },

  async getUsers(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.usersConfig,
      { root: true }
    );

    const response = await GET(`/users`, { params });
    context.commit("setUsers", response.data.users);
    return response;
  },

  async createUser({ commit, state }) {
    const response = await POST(`users`, state.user);
    commit("setUser", state.newUser);
    return response;
  },

  async updateUser({ state }) {
    return await PUT(
      `users/${state.user.reference}`,
      sanitizePayload(state.user, state.payloadModels.update)
    );
  },

  async patchUser({ state }, data) {
    return await PATCH(`users/${state.userProfile.reference}/patch`, data);
  },

  userPatchAction({ dispatch, state }, { key, additionalData = {} }) {
    return dispatch("patchUser", {
      [key]: state.formValue[key],
      ...additionalData,
    });
  },

  async deleteUser({ state }, ref) {
    return await DELETE(`users/${ref}`, state.user);
  },

  async getCandidateProfile({ commit }) {
    const response = await GET(`users/candidate`);
    commit("setCandidateProfile", response.data.candidate);
    return response;
  },

  async getUserProfile({ commit, state }, reference) {
    const params = await prepareUserParams(state.usersConfig);
    const response = await GET(`users/${reference}`, { params });
    commit("setUserProfile", response.data.user);
    return response;
  },

  async editUserProfile({ state }) {
    const userProfileData = { ...state.userProfile };

    delete userProfileData.roles;

    if (userProfileData.password && !userProfileData.password_confirmation) {
      delete userProfileData.password;
    }

    const response = await PUT(
      `users/${userProfileData.reference}`,
      sanitizePayload(userProfileData, state.payloadModels.updateProfile)
    );

    //commit("setUserProfile", response.data.user);

    return response;
  },

  async editCandidateProfile({ commit, state }, asDraft = false) {
    const candidateData = { ...state.candidateProfile };
    candidateData.as_draft = asDraft;

    const response = await PUT(`users/candidate`, candidateData);
    commit("setUserProfile", response.data.candidate);
    commit("setUser", response.data.candidate);
    return response;
  },

  async addPaymentMethod({ commit }) {
    const response = await POST(`store/payment-method`);
    commit("setUserProfile", response.data.user);
    return response;
  },

  async updateUserInternalComment(context, { reference, internal_comment }) {
    return await PUT(`users/${reference}/update-internal-comment`, {
      internal_comment,
    });
  },

  async getBillingPortal({ commit, state }) {
    if (state.billingPortalUrl) {
      return Promise.resolve(state.billingPortalUrl);
    }

    return await GET(`billing-portal`, {
      params: {
        return_url: window.location.href,
      },
    }).then((response) => {
      commit("setBillingPortalUrl", response.data.url);
      return response.data.url;
    });
  },

  async getSubscriptionsStatuses({ commit }) {
    return await GET(`subscriptions/statuses`).then((response) => {
      commit("setOnGoingSubscriptionCount", response.data.ongoing_count);
      commit("order/setOrderWithFailedPayment", response.data.failed_payment, {
        root: true,
      });
    });
  },

  async deactivateUser(context, reference) {
    return await PUT(`/users/${reference}/deactivate`);
  },

  async activateUser(context, reference) {
    return await PUT(`/users/${reference}/activate`);
  },
};
