<template>
  <div class="is-flex is-align-items-center">
    <b-tooltip class="tooltip-hidden-tablet-only" :label="statusName">
      <figure class="image is-16x16">
        <IconCircle class="icon-size-1_3" :color="statusColor" />
      </figure>
    </b-tooltip>

    <p class="body1 ml-2 has-text-grey-darker is-hidden-tablet-only">
      {{ statusName }}
    </p>
  </div>
</template>
<script>
export default {
  name: "status-order-card",
  props: ["status", "options"],

  computed: {
    statusColor() {
      return this.$t(`${this.options}.${this.status}.color`);
    },

    statusName() {
      return this.$t(`${this.options}.${this.status}.name`);
    },
  },
};
</script>
