export default {
  setEditor(state, editor) {
    state.checkEditingStatusCount = 0;
    state.editor = editor;
  },

  setEditorReadOnlyStatus(state, readOnly) {
    if (
      !state.editor?.readOnly ||
      state.editor.readOnly.isEnabled === readOnly
    ) {
      return;
    }

    state.editor.readOnly.toggle();
  },

  setIsContentSaving(state, isContentSaving) {
    state.isContentSaving = isContentSaving;
  },

  setEditingStatus(state, editingStatus) {
    state.editingStatus = editingStatus;
  },

  resetEditingStatus(state) {
    state.checkEditingStatusCount = 0;
    state.editingStatus = {
      under_editing: false,
      editing_by_authenticated_user: false,
    };
  },

  incrementCheckEditingStatusCount(state) {
    state.checkEditingStatusCount++;
  },
};
