export default () => ({
  activeStep: 0,
  steps: [],
  storageWebsiteId: null,
  storageOrderRef: null,
  isPayment: false,
  isSaving: false,
  isLoadingCheckout: false,
  isModalPaymentWireTransfert: false,
});
