import store from "@/store";

// Function to check authentication and redirect if necessary
const checkAuthentication = (requestedRoleGetterKey, to, next) => {
  store.dispatch("initialiseStore");

  const userHasRequestedRole = store.getters[requestedRoleGetterKey];
  const homepagePath = store.getters["auth/homepage"];

  if (!userHasRequestedRole && !homepagePath.startsWith(to.path)) {
    window.location.href = homepagePath;
  } else {
    next();
  }
};

export const applyMiddlewareToRoutes = (middleware, routes) => {
  return routes.map((route) => {
    route.beforeEnter = middleware;
    return route;
  });
};

export const customerMiddleware = (to, from, next) => {
  checkAuthentication("auth/client", to, next);
};

export const editorManagerMiddleware = (to, from, next) => {
  checkAuthentication("auth/editorManager", to, next);
};

export const adminMiddleware = (to, from, next) => {
  checkAuthentication("auth/admin", to, next);
};

export const correctorMiddleware = (to, from, next) => {
  checkAuthentication("auth/corrector", to, next);
};

export const writerMiddleware = (to, from, next) => {
  checkAuthentication("auth/writer", to, next);
};

export const candidateMiddleware = (to, from, next) => {
  checkAuthentication("auth/candidate", to, next);
};
