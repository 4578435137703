export default [
  {
    path: "profile",
    component: () => import("@/views/User/UserProfile.vue"),
  },
  {
    path: "favorite-writers",
    component: () => import("@/views/User/WritersFavorite.vue"),
  },
  {
    path: "website/:id/order/:reference/writers-rating",
    component: () => import("@/views/User/WritersRating.vue"),
  },
  {
    path: "/users/candidates",
    component: () => import("@/views/User/ListCandidates.vue"),
  },
  {
    name: "CandidateProfiles",
    path: "/users/candidates/:reference",
    component: () => import("@/views/User/DetailsCandidate.vue"),
  },
];
