var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{attrs:{"active":_vm.dataFetching},on:{"update:active":function($event){_vm.dataFetching=$event}}}),(_vm.isLoading)?_c('div',[(
        _vm.status === _vm.CANDIDATE_STATUSES.DRAFT ||
        _vm.status === _vm.CANDIDATE_STATUSES.AWAITING_CONTRACT
      )?_c('FormStepsCandidate',{attrs:{"isNewCandidate":_vm.isNewCandidate,"isLoading":_vm.isLoading},on:{"after-save":_vm.afterSave}}):_vm._e(),(
        _vm.status === _vm.CANDIDATE_STATUSES.PENDING ||
        _vm.status === _vm.CANDIDATE_STATUSES.REJECTED
      )?_c('CandidatePending',{attrs:{"isNewCandidate":_vm.isNewCandidate,"isLoading":_vm.isLoading}}):_vm._e(),(_vm.status === _vm.CANDIDATE_STATUSES.AWAITING_TEST)?_c('div',[_c('DashboardHeader'),_c('OrdersTab',{staticClass:"pt-4 p-2"})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }