export default () => ({
  websites: null,
  allWebsites: null,
  website: {
    domain: "",
    type_website: null,
    //url history
    about: "",
    about_files: [],
    about_url: "",
    //products
    activity: "",
    activity_files: [],
    activity_url: "",

    competitors: null,
    customers: "",
    customers_files: [],

    //values
    description: null,

    //range
    range: null,
    //aera
    scope: null,

    // assets
    strength: null,
    strength_url: "",
    strength_files: [],
    //targets
    targets: null,

    //voice
    address_the_reader: "",
    writings_company_name: "",
    tone_of_voice: "",
    talk_about_company: "",

    words_to_avoid: "",
    is_internal: false,
  },
  websitesConfig: {
    include: null,
    sort: null,
    sortDirection: null,
    page: null,
    filters: {},
    per_page: null,
  },
  websiteConfig: {
    include: "orders",
    with: "texts",
  },
  payloadModels: {
    update: [
      "name",
      "domain",
      "type_website",
      "company_id",
      "user_id",
      "about",
      "about_url",
      "about_files",
      "scope",
      "description",
      "activity",
      "activity_url",
      "activity_files",
      "range",
      "competitors",
      "targets",
      "strength",
      "strength_url",
      "strength_files",
      "customers",
      "customers_files",
      "tone_of_voice",
      "talk_about_company",
      "address_the_reader",
      "writings_company_name",
      "words_to_avoid",
    ],
  },
});
