<template>
  <span :class="'fi fi-' + flag"></span>
</template>

<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";
export default {
  name: "order-card-locale",
  components: {},
  props: ["locale"],
  data() {
    return {
      flag: this.locale === "en" ? "gb" : this.locale,
    };
  },
};
</script>
