<template>
  <div>
    <FormNewCandidate :isLoading="isLoading" :user="user" />
  </div>
</template>

<script>
import FormNewCandidate from "@/views/Form/FormNewCandidate";
import { mapState } from "vuex";
export default {
  name: "DashboardCandidate",
  components: {
    FormNewCandidate,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>
