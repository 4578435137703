import { GET, POST, PUT } from "@/config/backend";
import { formatDateToYYYYMMDD } from "@/utils/index";

export default {
  async getInvoices(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.invoicesConfig,
      { root: true }
    );

    const response = await GET(`/user/invoices`, { params });
    context.commit("setInvoices", response.data.invoices);
    return response;
  },

  async getInvoicesToPay(context, invoiceNumber) {
    const response = await GET(`/user/invoices/${invoiceNumber}`);
    context.commit("setInvoice", response.data.invoice);
    return response;
  },

  async getCheckoutInvoice(context, invoiceNumber) {
    return await GET(`/invoice/${invoiceNumber}/checkout`);
  },

  async getMonthlyInvoices(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.monthlyInvoicesConfig,
      { root: true }
    );

    const response = await GET(`/monthly-invoices`, { params });
    context.commit("setMonthlyInvoices", response.data.invoices);
    return response;
  },

  async finalizeDraftMonthlyInvoice(context, paymentId) {
    return await PUT(`monthly-invoices/${paymentId}/finalize`);
  },

  async getUnpaidMonthlyInvoice(context) {
    const response = await GET(`/invoice/unpaid-monthly-payment`);

    context.commit("setUnpaidMonthlyInvoice", response.data.invoice);

    return response;
  },

  async sendInvoicesWriter(context, data) {
    return await POST(`/writer/invoices/sent`, data);
  },

  async sendBackWithComment(context, data) {
    return await PUT(`writer/payment/${data.id}/comment`, data.content);
  },

  async getPaymentsWriter(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.orderPaymentsConfig,
      { root: true }
    );
    const response = await GET(`/writer/payments`, { params });

    context.commit("setOrderPayments", response.data.payments);

    return response;
  },

  async updatePaymentMethod(context, payload) {
    const formData = new FormData();
    formData.append("payment_method", payload.payment_method);

    if (payload.proof_of_transfer_file) {
      formData.append("proof_of_transfer_file", payload.proof_of_transfer_file);
    }

    return await POST(
      `invoices/${payload.invoice_number}/payment-method`,
      formData
    );
  },

  async updatePaymentStatus(context, payload) {
    let paidAt = null;

    if (payload.paid_at) {
      paidAt = formatDateToYYYYMMDD(payload.paid_at);
    }

    return await PUT(`invoices/${payload.invoice_number}/payment-status`, {
      paid: payload.paid,
      paid_at: paidAt,
    });
  },

  async payInvoiceWithCredit(context, reference) {
    return await POST(`/invoice/${reference}/pay-with-credit`);
  },

  async generateMonthlyInvoices() {
    return await POST(`/test/generate-monthly-invoices`);
  },

  async updateWriterInvoicePaymentStatus(context, payload) {
    let paidAt = null;

    if (payload.paid_at) {
      paidAt = formatDateToYYYYMMDD(payload.paid_at);
    }

    return await PUT(`writer/payments/${payload.payment_id}/status`, {
      paid_at: paidAt,
    });
  },
};
