import { GET } from "@/config/backend";

export default {
  async getBalance({ commit }, companyId) {
    const response = await GET(`companies/${companyId}/credits/balance`);
    commit("setBalance", response.data.credits);
    return response;
  },

  async getCreditHistories({ commit, dispatch, state }, companyId) {
    let params = await dispatch("prepareParams", state.creditHistoriesConfig, {
      root: true,
    });

    const response = await GET(`companies/${companyId}/credits/histories`, {
      params,
    });
    const creditHistoriesConfig = {
      page: response.data.credit_histories.current_page,
      per_page: response.data.credit_histories.per_page,
      total: response.data.credit_histories.total,
    };

    commit("setCreditHistories", response.data.credit_histories.data);
    commit("setCreditHistoriesConfig", creditHistoriesConfig);

    return response;
  },
};
