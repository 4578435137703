import Vue from "vue";
import App from "@/App.vue";

import title from "@/mixins/title";
import * as VueGoogleMaps from "vue2-google-maps";

import i18n from "@/i18n/i18n";

import router from "@/router";
import store from "@/store";

//import 3rd parties lib
import Buefy from "buefy";
import * as Sentry from "@sentry/vue";

// icon for buefy
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Components from "@/config/components";

// config
import "@/config/styles";
import "@/config/filters";

library.add(fas);
Vue.component("vue-fontawesome", FontAwesomeIcon);
Vue.use(Components);

Vue.mixin(title);

Vue.use(Buefy, {
  defaultIconPack: "fas",
});

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDZ6hxheYHqycRjIO9bDZz-2eR5W4evKKY",
    libraries: "places",
  },
});

if (process.env.VUE_APP_SENTRY_DSN) {
  const SENTRY_ENV = process.env.VUE_APP_SENTRY_ENV || "production";

  if (["development", "staging", "preproduction"].includes(SENTRY_ENV)) {
    console.log("Sentry is enabled");
  }

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logErrors: true,
    environment: SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ["localhost", process.env.VUE_APP_BACKEND_URL, /^\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.configureScope((scope) => {
    let user = localStorage.getItem("user");

    if (user) {
      user = JSON.parse(user);

      scope.setUser({
        email: user.email,
        username: `${user.firstname} ${user.lastname}`,
        roles: user.roles.map((role) => role.name),
      });
    } else {
      scope.setUser(null);
    }
  });
}

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.dispatch("initialiseStore", router);
  },
  i18n,
  render: (h) => h(App),
}).$mount("#app");
