export default {
  homepage(state) {
    return state.homepage + "/";
  },

  user(state) {
    return state.user;
  },

  userReference(state) {
    return state.user.reference;
  },

  admin(state) {
    return state.roles.includes("admin");
  },

  writer(state) {
    return state.roles.includes("writer");
  },

  client(state) {
    return state.roles.includes("client");
  },

  mainClient(state) {
    return state.roles.includes("main-client");
  },

  editorManager(state) {
    return state.roles.includes("editor-manager");
  },

  corrector(state) {
    return state.roles.includes("corrector");
  },

  candidate(state) {
    return state.roles.includes("candidate");
  },

  recruiter(state) {
    return state.roles.includes("recruiter");
  },

  correctorOrEditorManager(state) {
    return (
      state.roles.includes("corrector") ||
      state.roles.includes("editor-manager")
    );
  },

  correctorOrEditorManagerOrRecruiter(state) {
    return (
      state.roles.includes("corrector") ||
      state.roles.includes("editor-manager") ||
      state.roles.includes("recruiter")
    );
  },

  adminOrRecruiter(state) {
    return state.roles.includes("admin") || state.roles.includes("recruiter");
  },

  clientRoleOnly(state) {
    const rolesLength = state.roles.length;

    return (
      state.roles.includes("client") &&
      (rolesLength === 1 ||
        (rolesLength === 2 && state.roles.includes("main-client")))
    );
  },

  clientOrWriterRoleOnly(state) {
    const rolesLength = state.roles.length;

    return (
      (state.roles.includes("client") &&
        (rolesLength === 1 ||
          (rolesLength === 2 && state.roles.includes("main-client")))) ||
      (rolesLength === 1 && state.roles.includes("writer"))
    );
  },

  staffRole(state) {
    return (
      state.roles.includes("admin") ||
      state.roles.includes("editor-manager") ||
      state.roles.includes("recruiter") ||
      state.roles.includes("corrector")
    );
  },
};
