import { GET } from "@/config/backend";

function getColorForWord(score, colorCheckWord) {
  if (score >= colorCheckWord.blue.min && score < colorCheckWord.blue.max) {
    return "blue";
  } else if (
    score >= colorCheckWord.green.min &&
    score < colorCheckWord.green.max
  ) {
    return "green";
  } else if (
    score >= colorCheckWord.orange.min &&
    score < colorCheckWord.orange.max
  ) {
    return "orange";
  } else if (score >= colorCheckWord.red.min) {
    return "red";
  } else {
    return null;
  }
}

export default {
  async getYtgGuide({ commit }, reference) {
    const response = await GET(`ytg/${reference}/guide`);
    commit("setYtgGuide", response.data.guide);
    return response;
  },

  async getYtgWords({ dispatch }, reference) {
    const response = await GET(`ytg/${reference}/words`);
    dispatch("setScoresRecWords", response.data.guide);
    return response;
  },

  async getYtgAnalyze({ dispatch }, reference) {
    const response = await GET(`ytg/${reference}/analyze`);
    dispatch("setScoresRecWords", response.data.guide);
    return response;
  },

  setScoresRecWords(context, response) {
    context.commit("setYtgRecWords", response.recommended_words);
    context.commit("setYtgCompWords", response.complementary_words);
    context.commit("setYtgSerp", response.serp_result);

    if (response.check) {
      context.commit("setYtgGuideId", response.ytg_guide_id);
      context.commit("setYtgWordsCheck", response.check);
      context.dispatch("createColorInterval");
      context.dispatch("createColorRecWords");

      if (response.check.scores?.length && context.state.ytgWordsCheck) {
        context.commit("setYtgWordsScores", response.check.scores);
        context.dispatch("addColorRecWords");
      }

      context.commit("setYtgDSEO", response.check.danger);
      context.commit("setYtgSOSEO", response.check.score);
    }
  },

  createColorInterval(context) {
    if (context.state.ytgRecWords && context.state.ytgWordsCheck) {
      const colorInterval = context.state.ytgRecWords.map((element) => {
        const { blue, orange, red, green } = context.state.ytgWordsCheck;
        const getColorMinMax = (color) => {
          const found = color.find((el) => el[0] === element);
          return {
            min: found && found[1] ? found[1] : null,
            max: found && found[2] ? found[2] : null,
          };
        };

        return element
          ? {
              name: element,
              blue: getColorMinMax(blue),
              green: getColorMinMax(green),
              orange: getColorMinMax(orange),
              red: getColorMinMax(red),
            }
          : null;
      });

      context.commit("setYtgWordsCheck", colorInterval.filter(Boolean));
    }
  },

  createColorRecWords(context) {
    let recWords = [];

    if (context.state.ytgRecWords) {
      context.state.ytgRecWords.forEach((element) => {
        recWords.push({ name: element, color: null });
      });

      if (recWords) {
        context.commit("setYtgRecWords", recWords);
      }
    }
  },

  addColorRecWords(context) {
    if (context.state.ytgWordsScores?.length) {
      let recWords = [];

      context.state.ytgWordsScores.forEach((element) => {
        if (context.state.ytgWordsCheck && element) {
          let color = null;
          let colorCheckWord = context.state.ytgWordsCheck.find(
            (el) => el.name === element[0]
          );

          if (colorCheckWord) {
            color = getColorForWord(element[1], colorCheckWord);
          }

          recWords.push({ name: element[0], color: color });
        }
      });

      if (recWords) {
        context.commit("setYtgRecWords", recWords);
      }
    }
  },
};
