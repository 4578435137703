<template>
  <div>
    <DashboardHeader />
    <OrdersTab class="pt-4 p-2" :default-per-page="defaultPerPage" />
  </div>
</template>

<script>
import DashboardHeader from "@/components/UI/DashboardHeader";
import OrdersTab from "@/components/Model/Order/OrdersTab";
export default {
  name: "DashboardMain",
  components: {
    DashboardHeader,
    OrdersTab,
  },

  computed: {
    defaultPerPage() {
      return this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 20;
    },
  },
};
</script>
