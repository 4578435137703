export default () => ({
  textsConfig: {
    per_page: 20,
  },
  textConfig: {
    include: "",
  },
  orderText: null,
  orderTexts: [],
  validatedTextsCount: 0,
  textTracking: null,
  textCommentTracking: null,
  orderReference: null,
  textReference: null,
  wordsCounter: 0,
  duplicateContentCounter: 0,
  keywordPercentage: null,
  sentenceLengthAverage: 0,
  longSentencePercentage: 0,
  editorStatistics: 0,
  textComments: {},
  textBlockComment: {},
  textKeywords: {
    keywords_correction: null,
  },
  textSubjects: {
    subjects_correction: null,
  },
  payloadModels: {
    updateContent: [
      "readability_score",
      "keywords_density",
      "written_content_length",
      "orderReference",
      "textReference",
      "content",
      "blocks.*.id",
      "blocks.*.type",
      "blocks.*.block_type",
      "blocks.*.instructions",
      "blocks.*.type",
      "blocks.*.data.id",
      "blocks.*.data.text",
      "blocks.*.data.content",
      "blocks.*.data.withHeadings",
      "blocks.*.data.items",
      "blocks.*.data.style",

      // quote block
      "blocks.*.data.caption",
      "blocks.*.data.alignment",

      // image block
      "blocks.*.data.stretched",
      "blocks.*.data.withBackground",
      "blocks.*.data.withBorder",
      "blocks.*.data.file",

      // code block
      "blocks.*.data.code",

      "blocks.*.data.level",
      "blocks.*.data.block_id",
      // meta block
      "blocks.*.pivot.id",
      "blocks.*.pivot.content",
      "blocks.*.pivot.position",
      "blocks.*.pivot.block_id",
    ],
  },
});
