import { POST } from "@/config/backend";

export default {
  registerClient({ commit }, newClientData) {
    return POST(`/register/client`, newClientData).then((response) => {
      commit("auth/setToken", response.data.token, { root: true });
      commit("auth/setUser", response.data.user, { root: true });
    });
  },

  registerCandidate({ commit }, newCandidateData) {
    return POST(`/register/candidate`, newCandidateData).then((response) => {
      commit("auth/setToken", response.data.token, { root: true });
      commit("auth/setUser", response.data.user, { root: true });
    });
  },
};
