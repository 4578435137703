export default () => ({
  ytgGuide: null,
  ytgRecWords: null,
  ytgCompWords: null,
  ytgWordsCheck: null,
  ytgWordsScores: null,
  ytgSerp: null,
  ytgDseo: null,
  ytgSoseo: null,
  ytgSOSEOMinRef: 5,
  ytgSOSEOMin: null,
  ytgDSEOMax: null,
  isYtgGuideLaunch: false,
  ytgGuideId: null,
});
