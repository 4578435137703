import { sanitizePayload } from "@/utils/payload";
import { GET, POST, PUT, PATCH } from "@/config/backend";

function prepareParams(config) {
  return new Promise((resolve) => {
    let params = {};

    if (config.page) {
      params.page = config.page;
    }

    if (config.include) {
      params.include = config.include;
    }

    if (config.with) {
      params.with = config.with;
    }

    if (config.resource_fields) {
      params.resource_fields = config.resource_fields;
    }

    if (config.sort) {
      const sortField =
        config.sortDirection === "desc" ? `-${config.sort}` : config.sort;

      params.sort = sortField;
    }

    if (config.per_page) {
      params.per_page = config.per_page;
    }

    if (config.filters) {
      for (const [key, value] of Object.entries(config.filters)) {
        params[`filter[${key}]`] = value;
      }
    }

    resolve(params);
  });
}
export default {
  async getWebsitesDashboard({ state, commit }) {
    const params = await prepareParams(state.websitesConfig);
    const response = await GET(`/websites/dashboard`, { params });
    commit("setWebSites", response.data.websites);
    return response;
  },

  async getWebsites({ commit, state }) {
    const params = await prepareParams(state.websitesConfig);
    const response = await GET(`websites`, { params });
    commit("setWebSites", response.data.websites.data);
    return response;
  },

  async getAllWebsites({ commit, state }) {
    const params = await prepareParams(state.websitesConfig);
    const response = await GET(`websites/all`, { params });
    commit("setAllWebSites", response.data.websites);
    return response;
  },

  async getWebsite(context, reference) {
    const params = await prepareParams(context.state.websiteConfig);
    const response = await GET(`websites/${reference}`, { params });
    context.commit("setWebSite", response.data.website);
    context.dispatch("addThematicWebsite");
    context.dispatch("getThematicsName");
    return response;
  },

  async createWebsite({ commit, state }) {
    const response = await POST(`websites`, state.website);
    commit("setWebSite", response.data.website);
    return response;
  },

  async createWebsiteAdmin({ commit, state }, userReference) {
    const response = await POST(
      `websites/create/${userReference}`,
      state.website
    );
    commit("setWebSite", response.data.website);
    return response;
  },

  async editWebsite({ commit, state }, reference) {
    const response = await PUT(
      `websites/${reference}`,
      sanitizePayload(state.website, state.payloadModels.update)
    );
    commit("setWebSite", response.data.website);
    return response;
  },

  async patchWebsite({ state }, data) {
    const orderReference = state.website.order_reference;

    return await PATCH(
      `websites/${state.website.id}/patch?order_reference=${orderReference}`,
      data
    );
  },

  websitePatchAction({ state, dispatch, commit }, { key }) {
    commit("order/setOrderBriefSaving", true, { root: true });
    const value = state.website[key];

    return dispatch("patchWebsite", { [key]: value }).then((response) => {
      if (Array.isArray(value) && value[0]?.encodedFile !== undefined) {
        state.website[key] = response.data.website[key];
      }

      commit("order/setOrderBriefSaving", false, { root: true });
    });
  },

  async checkDomain({ state }, userReference) {
    return await POST(`websites/check/domain`, {
      ...state.website,
      user_reference: userReference,
    });
  },

  async createDraftWebsite({ commit, state }) {
    const response = await POST(`websites/save`, state.website);
    commit("setWebSite", response.data.website);
    return response;
  },

  async createDraftWebsiteAdmin({ commit, state }, userReference) {
    const response = await POST(
      `websites/save/${userReference}`,
      state.website
    );
    commit("setWebSite", response.data.website);
    return response;
  },

  async editDraftWebsite({ commit, state }) {
    const response = await PUT(
      `websites/save/${state.website.id}`,
      state.website
    );
    commit("setWebSite", response.data.website);
    return response;
  },

  addThematicWebsite(context) {
    let thematics = [];
    context.state.website.thematics.data.forEach((element) => {
      thematics.push(element.id);
    });
    context.state.website.thematic_id = thematics;
  },

  getThematicsName(context) {
    let thematicsName = "";

    context.state.website.thematics.data.forEach((element, idx, array) => {
      thematicsName += element.name;

      if (idx !== array.length - 1) {
        thematicsName += ", ";
      }
    });

    context.state.website.thematics_name = thematicsName;
  },
};
