export default () => ({
  company: null,
  newCompany: {
    name: null,
    address: null,
    zipcode: null,
    city: null,
    siren: null,
    tva: null,
    credits: 0,
  },
  formValue: {},
  companies: null,
  companiesConfig: {},
  companySearch: null,
  payloadModels: {
    update: [
      "name",
      "address",
      "address2",
      "zipcode",
      "city",
      "country",
      "latitude",
      "longitude",
      "siren",
      "tva",
      "iban",
      "subject_vat",
      { attr: "price", includeIf: "isAdmin" },
      { attr: "monthly_payment", includeIf: "isAdmin" },
      { attr: "credits", includeIf: "isAdmin" },
      { attr: "pennylane_id", includeIf: "isAdmin" },
      { attr: "pennylane_supplier_id", includeIf: "isAdmin" },
      { attr: "internal_comment", includeIf: "isStaffRole" },
    ],
  },
});
