export default {
  data() {
    return {
      componentReady: false,
    };
  },
  methods: {
    async forceRerender() {
      this.componentReady = false;
      await this.$nextTick();
      this.componentReady = true;
    },
  },
};
