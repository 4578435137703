import { GET, POST, DELETE, PUT } from "@/config/backend";

export default {
  async getCollaborators(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.collaboratorsConfig,
      { root: true }
    );

    const response = await GET(`/collaborators`, { params });

    context.commit("setCollaborators", response.data.collaborators);

    return response;
  },

  async createCollaborator({ commit, state }) {
    const response = await POST(`collaborators`, state.collaborator);
    commit("setCollaborator", state.newCollaborator);
    return response;
  },

  async updateCollaborator({ state }) {
    return await PUT(
      `collaborators/${state.collaborator.reference}`,
      state.collaborator
    );
  },

  async deleteCollaborator({ state }, ref) {
    return await DELETE(`collaborators/${ref}`, state.collaborator);
  },
};
