import _get from "lodash/get";
import _set from "lodash/set";
import store from "@/store/index";

/*

Sanitizes the payload before sending it to the server. This function extracts specific values using the following format as the "acceptedPayloadAttributes" parameter :
- "attr"
- "attr.*.attr"
- "attr.*.attr.attr"

*/

const conditions = {
  isAdmin: () => store.getters["auth/admin"],
  isStaffRole: () => store.getters["auth/staffRole"],
};

const sanitizePayload = (payload, acceptedPayloadAttributes) => {
  const sanitizedPayload = {};

  acceptedPayloadAttributes.forEach((path) => {
    if (typeof path === "object") {
      if (path.includeIf && !conditions[path.includeIf]()) {
        return;
      }

      path = path.attr;
    }

    if (path.includes("*")) {
      const [head, ...tail] = path.split(".");
      const array = _get(payload, head);

      if (Array.isArray(array)) {
        array.forEach((item, index) => {
          const newPath = `${head}[${index}].` + tail.slice(1).join(".");
          const value = _get(payload, newPath);

          if (value !== undefined) {
            _set(sanitizedPayload, newPath, value);
          }
        });
      }
    } else {
      const value = _get(payload, path);

      if (value !== undefined) {
        _set(sanitizedPayload, path, value);
      }
    }
  });

  return sanitizedPayload;
};

export { sanitizePayload };
