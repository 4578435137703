export default [
  {
    path: "website/:id/order/:reference/text/:key",
    component: () => import("@/views/Text/Text.vue"),
  },
  {
    path: "website/:id/order/:reference/text/:key/tracking",
    component: () => import("@/views/Text/TextTracking.vue"),
  },
];
