export default {
  setOptions(state, value) {
    state.options = value;
  },

  setOption(state, value) {
    state.option = value;
  },

  setDefaultOption(state) {
    state.option = JSON.parse(JSON.stringify(state.newOption));
  },
};
