import { GET, POST, DELETE, PUT, PATCH } from "@/config/backend";

function prepareParams(config) {
  return new Promise((resolve) => {
    var field = config.sort;

    if (config.sortDirection === "desc") {
      field = "-" + field;
    }

    var params = {
      page: config.page,
      include: config.include,
      with: config.with,
      sort: field,
      per_page: config.per_page,
    };

    if (config.filters) {
      for (const [key, value] of Object.entries(config.filters)) {
        params[`filter[${key}]`] = value;
      }
    }

    if (config.resource_fields) {
      params.resource_fields = config.resource_fields;
    }

    resolve(params);
  });
}
export default {
  async getWriters({ commit, state }) {
    const params = await prepareParams(state.writersConfig);
    const response = await GET(`/users/writers`, { params });
    commit("setWriters", response.data.writers.data);
    return response;
  },

  async getWriterStats({ commit }, reference) {
    const response = await GET(`/users/writers/${reference}/stats`);
    commit("setWriterStats", response.data);

    return response;
  },

  async getCandidates({ commit, state }) {
    const params = await prepareParams(state.candidatesConfig);
    const response = await GET(`/users/candidates`, { params });
    commit("setCandidates", response.data.writers);
    return response;
  },

  async getCandidate({ commit }, reference) {
    const response = await GET(`/users/candidates/${reference}`);
    commit("setCandidate", response.data.candidate);
    return response.data.candidate;
  },

  async validateCandidate({ commit }, { reference, params }) {
    const response = await PUT(`/users/candidates/${reference}`, params);
    commit("setCandidate", response.data.candidate);
    return response.data.candidate;
  },

  async getOrderWriters({ commit }, reference) {
    const response = await GET(`orders/${reference}/writers`);
    commit("setCompatibleWriters", response.data.writers.data);
    return response;
  },

  async sendOrderWriter(context, reference) {
    return await POST(`orders/${reference.order}/writers`, reference);
  },

  async sendApplication(context, reference) {
    return await POST(`orders/${reference}/apply`);
  },

  async deleteApplication(context, reference) {
    return await DELETE(`orders/${reference}/apply `);
  },

  async getApplicants({ commit }, reference) {
    const response = await GET(`orders/${reference}/applicants`);
    commit("setApplicants", response.data.users);
    return response;
  },

  async valideApplicant({ commit }, reference) {
    const response = await POST(
      `orders/${reference.order}/applicants/${reference.user} `
    );
    commit("removeFromApplicants", reference.user);
    return response;
  },

  async deleteApplicant({ commit }, reference) {
    const response = await DELETE(
      `orders/${reference.order}/applicants/${reference.user} `
    );
    commit("removeFromApplicants", reference.user);
    return response;
  },

  async sendTextApplication(context, reference) {
    return await POST(`texts/${reference}/apply`);
  },

  async sendMultipleTextApplications(context, { references }) {
    return await POST(`texts/apply-to-multiple`, { references });
  },

  async getWriterPreference({ commit, dispatch }, userReference) {
    const response = await GET(`writer-preferences/${userReference}`);

    if (response.data) {
      commit("setWriterPreferences", response.data.preferences);
    }

    dispatch("createSubjectsIdArray");
    return response;
  },

  async createWriterPreference({ commit, state }) {
    const response = await POST(`writer-preferences`, state.writerPreferences);
    commit("setWriterPreferences", response.data.preferences);
    return response;
  },

  async editWriterPreference({ commit, state, dispatch }, reference) {
    const response = await PUT(
      `writer-preferences/${reference}`,
      state.writerPreferences
    );
    commit("setWriterPreferences", response.data.preferences);
    dispatch("createSubjectsIdArray");
    return response;
  },

  async patchWriterPreference({ state }, data) {
    return await PATCH(
      `writer-preferences/${state.userProfile.reference}/patch`,
      data
    );
  },

  writerPreferencePatchAction({ dispatch }, { key, value }) {
    return dispatch("patchWriterPreference", { [key]: value }).then(() => {
      dispatch("getWriterStats", this.state.userProfile.reference);
    });
  },

  async getFavoriteWriters({ commit, state }) {
    const params = await prepareParams(state.writersConfig);
    const response = await GET(`favorite-writers`, { params });
    commit("setFavoriteWriters", response.data.favoriteWriters);
    return response;
  },

  async getFavoriteWritersCount({ commit }) {
    const response = await GET(`favorite-writers/count`);
    commit("setFavoriteWritersCount", response.data.favoriteWritersCount);
    return response;
  },

  async getFavoriteWritersByOrderRef({ commit, state }, orderRef) {
    const params = await prepareParams(state.writersConfig);
    const response = await GET(`orders/${orderRef}/favorite-writers`, {
      params,
    });
    commit("setFavoriteWriters", response.data.favoriteWriters);
    return response;
  },

  async getMyWriters({ commit, state }, orderRef) {
    const params = prepareParams(state.writersConfig);
    const response = await GET(`/client/writers/${orderRef}`, { params });
    commit("setMyWriters", response.data.writers);
    return response;
  },

  async addFavoriteWriters({ commit }, userReference) {
    const response = await POST(`favorite-writers/${userReference}/add`);
    commit("setFavoriteWriters", response.data.favoriteWriters);
    return response;
  },

  async removeFavoriteWriters({ commit }, userReference) {
    const response = await DELETE(`favorite-writers/${userReference}/delete`);
    commit("setFavoriteWriters", response.data.favoriteWriters);
    return response;
  },

  async applaudWriter(context, userReference) {
    return await POST(`writer/${userReference}/applaud`);
  },

  createSubjectsIdArray(context) {
    let options = [];

    if (context.state.writerPreferences.subjects) {
      context.state.writerPreferences.subjects.forEach((element) => {
        if (element.id) {
          options.push(element.id);
        }
      });
      context.commit("setWritersPrefSubjectsId", options);
    }
  },
};
