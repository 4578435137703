<template>
  <div v-if="isLoaded">
    <div class="isSmallField mt-2" v-if="isSmallField">
      <b-field :label="field.label ? field.label : ''">
        <b-select v-model="selected" class="is-fullwidth">
          <option v-for="option in options" :key="option" :value="option">
            {{ $t(`wizard.template.type.${option}`) }}
          </option>
        </b-select>
      </b-field>
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
    <div v-else>
      <div class="columns is-multiline mt-2">
        <b-field
          v-for="option in options"
          :key="option"
          class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
        >
          <b-radio-button
            v-model="selected"
            type="is-pink-light"
            :native-value="option"
          >
            {{ $t(`wizard.template.type.${option}`) }}
          </b-radio-button>
        </b-field>
      </div>
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { TEMPLATE_TYPES } from "@/utils/constants";
import CardValidationField from "@/components/UI/card/CardValidationField";

export default {
  name: "TemplateSelect",
  props: ["field", "indexStep", "indexField", "isSmallField", "isNextStep"],
  components: { CardValidationField },
  data() {
    return {
      selected: null,
      templateFilters: {},
      isLoaded: false,
      options: [],
      initialValue: null,
    };
  },
  computed: {
    ...mapState("template", ["templates", "customTemplates", "templateConfig"]),
    ...mapState("order", ["newOrder"]),
  },
  methods: {
    ...mapActions("template", ["getTemplates"]),
    ...mapMutations("template", ["setPreserveTemplateState"]),

    getData() {
      this.selected = this.field.value
        ? this.field.value
        : TEMPLATE_TYPES.SKIBIX_TEMPLATE;
      this.initialValue = this.selected;

      this.setTemplateFilters();

      this.getTemplates()
        .then(() => {
          const customTemplatesAvailable =
            this.customTemplates && this.customTemplates.length > 0;

          this.updateOptions(customTemplatesAvailable);

          this.handleTemplateSelection(customTemplatesAvailable);

          this.isLoaded = true;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },

    setTemplateFilters() {
      this.templateFilters["is_public"] = 1;
      this.templateFilters["order_type_id"] = this.newOrder["order_type"];
      this.templateConfig.filters = this.templateFilters;
    },

    updateOptions(customTemplatesAvailable) {
      const templatesAvailable = this.templates && this.templates.length > 0;

      if (templatesAvailable && customTemplatesAvailable) {
        this.options = [
          TEMPLATE_TYPES.SKIBIX_TEMPLATE,
          TEMPLATE_TYPES.NEW_TEMPLATE,
          TEMPLATE_TYPES.CUSTOM_TEMPLATE,
        ];
      } else if (templatesAvailable) {
        this.options = [
          TEMPLATE_TYPES.SKIBIX_TEMPLATE,
          TEMPLATE_TYPES.NEW_TEMPLATE,
        ];
      } else if (customTemplatesAvailable) {
        this.options = [
          TEMPLATE_TYPES.NEW_TEMPLATE,
          TEMPLATE_TYPES.CUSTOM_TEMPLATE,
        ];
      } else {
        this.options = [TEMPLATE_TYPES.NEW_TEMPLATE];
      }
    },

    handleTemplateSelection(customTemplatesAvailable) {
      if (this.newOrder.template_id) {
        const customTemplateIsSelected =
          customTemplatesAvailable &&
          this.customTemplates.find(
            (template) => template.id === this.newOrder.template_id
          );

        if (customTemplateIsSelected) {
          this.selected = TEMPLATE_TYPES.CUSTOM_TEMPLATE;
          this.$store.state.template.templateSelected.template_custom_selected =
            this.newOrder.template_id;
        }
      }
    },
  },

  created() {
    this.getData();
  },

  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
      });
    },
  },

  beforeDestroy() {
    if (this.initialValue !== this.selected) {
      this.setPreserveTemplateState(false);
    }
  },
};
</script>
