<template>
  <div
    ref="modal"
    class="modal is-fullheight columns is-align-items-flex-end animate__animated animate__slideInRight mb-0 pb-0 mt-0"
    :class="{ 'is-hidden': showBackdrop === false }"
  >
    <div
      class="column is-fullheight mb-0 pb-0 has-background-white overflow-y-auto"
      :class="{
        'is-fullwidth': isFullWidth,
        'is-half': isHalf,
        'is-auto-width': !isHalf,
      }"
    >
      <!-- Modal content -->
      <div class="is-fullheight is-half is-right p-4">
        <!-- Modal header -->
        <div class="columns p-2">
          <slot name="header"></slot>
        </div>

        <!-- Modal body -->
        <slot />

        <!-- Modal footer -->
        <div v-if="!!$slots.footer">
          <div
            class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModalVue",
  props: {
    isHalf: {
      type: Boolean,
      default: true,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["showBackdrop"]),
  },
  methods: {
    show() {
      this.$store.commit("setBackdrop", true);
    },
    hide() {
      this.$store.commit("setBackdrop", false);
    },
  },
};
</script>

<style>
.modal {
  z-index: 950 !important;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.is-auto-width {
  min-width: 50%;
}
</style>
