export default {
  setWriters(state, value) {
    state.writers = value;
  },
  setWriterStats(state, value) {
    state.writerStats = value;
  },
  setCandidates(state, value) {
    state.candidates = value;
  },
  setCandidatesConfig(state, value) {
    state.candidatesConfig = value;
  },
  setApplicants(state, value) {
    state.applicants = value;
  },
  removeFromApplicants(state, applicantReference) {
    state.applicants = state.applicants.filter(
      (item) => item.reference !== applicantReference
    );
  },
  setCandidate(state, value) {
    state.candidate = value;
  },
  setCompatibleWriters(state, value) {
    state.compatibleWriters = value;
  },
  setWriterPreferences(state, data) {
    state.writerPreferences = data;
  },
  setWritersConfig(state, data) {
    state.writersConfig = data;
  },
  setWritersPrefSubjectsId(state, data) {
    state.writerPreferences.subjects = data;
  },
  setFavoriteWriters(state, data) {
    state.favoriteWriters = data;
  },
  setFavoriteWritersCount(state, count) {
    state.favoriteWritersCount = count;
  },
  setMyWriters(state, data) {
    state.myWriters = data;
  },
  setPreferencesFormValue(state, data) {
    state.preferencesFormValue = data;
  },
};
