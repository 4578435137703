const prepareTextTracking = (value) => {
  return value.filter((item) => item.entity !== "Comment");
};

const prepareTextCommentTracking = (value) => {
  return value
    .filter(
      (item) =>
        item.entity === "Comment" &&
        item.refer_to_entity === "OrderText" &&
        item.new_value?.correction
    )
    .map((item) => ({
      user: item.user ? { ...item.user, ordered_roles: item.user.roles } : null,
      message: item.new_value.correction
        ? item.new_value.correction.message
        : null,
      correction: item.new_value.correction ? item.new_value.correction : null,
      created_at: item.created_at,
    }));
};

export default {
  setOrderTexts(state, value) {
    state.orderTexts = value;
  },
  resetOrderTexts(state) {
    state.orderTexts = [];
  },
  setOrderText(state, value) {
    state.orderText = value;
  },
  setTextsConfig(state, value) {
    state.textsConfig = value;
  },
  setTextConfig(state, value) {
    state.textConfig = value;
  },
  setTextTracking(state, value) {
    state.textTracking = prepareTextTracking(value);
    state.textCommentTracking = prepareTextCommentTracking(value);
  },
  setOrderReference(state, value) {
    state.orderReference = value;
  },
  setTextReference(state, value) {
    state.textReference = value;
  },
  setWordsCounter(state, value) {
    state.wordsCounter = value;
  },
  setDuplicateContentCounter(state, value) {
    state.duplicateContentCounter = value;
  },
  setSentenceLengthAverage(state, value) {
    state.sentenceLengthAverage = value;
  },
  setLongSentencePercentage(state, value) {
    state.longSentencePercentage = value;
  },
  setKeywordPercentage(state, value) {
    state.keywordPercentage = value;
  },
  setEditorStatistics(state, value) {
    state.editorStatistics = value;
  },
  setTextBlockComment(state, value) {
    state.textBlockComment = value;
  },
  setTextComments(state, value) {
    state.textComments = value;
  },
  setTextKeywords(state, value) {
    state.textKeywords.keywords_correction = value;
  },
  setValidatedTextsCount(state, value) {
    state.validatedTextsCount = value;
  },
};
