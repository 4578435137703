<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value"
        @start-editing="isEditing = true"
      />
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>

    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <b-field :label="field.label ? field.label : ''">
        <div class="is-flex is-align-items-center">
          <b-input
            v-model="selected"
            type="textarea"
            class="b-input mt-2 width-100"
            :placeholder="field.placeholder ? field.placeholder : ''"
            :disabled="disabled === true"
          />

          <PartialSaveFormValueButton :field="field" :callback="stopEditing" />
        </div>
      </b-field>

      <CardValidationField
        v-if="!selected || selected === ' '"
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "BriefTextarea",
  props: ["field", "indexStep", "indexField", "isNextStep", "disabled"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : null,
    };
  },
  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue ? newValue : null,
      });
    },
  },
  methods: {
    stopEditing() {
      this.isEditing = false;
    },
  },
};
</script>
