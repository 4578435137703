<template>
  <div>
    <PopupUnpaidMonthlyInvoice v-if="mainClient" />
    <PopupOrderSubscriptionWithFailedPayment v-if="orderWithFailedPayment" />
    <template v-else>
      <DashboardHeader />
      <MySites dashboard-view />
    </template>
  </div>
</template>

<script>
import DashboardHeader from "@/components/UI/DashboardHeader";
import PopupUnpaidMonthlyInvoice from "@/components/Model/Invoice/PopupUnpaidMonthlyInvoice";
import PopupOrderSubscriptionWithFailedPayment from "@/components/Model/Order/OrderSubscription/PopupOrderSubscriptionWithFailedPayment";
import MySites from "@/views/Site/Sites";
import { mapGetters, mapState } from "vuex";

export default {
  name: "DashboardCustomer",
  components: {
    DashboardHeader,
    MySites,
    PopupUnpaidMonthlyInvoice,
    PopupOrderSubscriptionWithFailedPayment,
  },
  computed: {
    ...mapGetters("auth", ["mainClient", "client"]),
    ...mapState("order", ["orderWithFailedPayment"]),
  },
};
</script>
