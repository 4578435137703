import i18n from "@/i18n/i18n";
import store from "@/store";

function getTitle(vm) {
  const { title } = vm.$options;

  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}

function getTitleDomain(vm) {
  const { titleAppName } = vm.$options;

  if (titleAppName) {
    return typeof titleAppName === "function"
      ? titleAppName.call(vm)
      : titleAppName;
  }
}

export default {
  created() {
    const title = getTitle(this);
    const titleAppName = getTitleDomain(this);

    if (title) {
      this.setTitle(title, titleAppName);
    }
  },

  methods: {
    setTitle(title, appName) {
      let translatedTitle = i18n.te(title) ? i18n.t(title) : title;
      let formattedDomain = appName ? appName : "Skribix";

      document.title = `${translatedTitle} - ${formattedDomain}`;
      store.commit("auth/setTitle", translatedTitle);
    },
  },
};
