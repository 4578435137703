<template>
  <span class="ribbon-container">
    <span class="ribbon has-text-white">
      <component :is="icon"></component>
    </span>
  </span>
</template>

<script>
export default {
  name: "icon-ribbon",
  props: ["icon"],
};
</script>
