import axios from "axios";
import router from "@/router";
import store from "@/store";
import { captureException as SentryCaptureException } from "@sentry/vue";
/*
|--------------------------------------------------------------------------
| Axios Base config
|--------------------------------------------------------------------------
|
*/
axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_URL}/api`;

/*
|--------------------------------------------------------------------------
| Axios Request Interceptor
|--------------------------------------------------------------------------
|
*/
axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");

    if (token) config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },

  (error) => {
    console.log("interceptors.request", error);
    return Promise.reject(error);
  }
);

/*
|--------------------------------------------------------------------------
| Axios Response Interceptor
|--------------------------------------------------------------------------
|
*/
axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if ([500, 403, 404].includes(error.response.status)) {
      // Sentry capture error
      SentryCaptureException(error);
    }

    if (error.response.status && error.response.status === 401) {
      localStorage.removeItem("token");
      router.push("/");
    }

    if (error.response.status && error.response.status === 404) {
      router.push("/404");
    }

    // if (error.response.status && error.response.status === 403) {
    //   router.push("/403");
    // }

    return Promise.reject(error);
  }
);

export async function GET(url, options) {
  try {
    return await axios.get(url, options);
  } catch (error) {
    return await handleError(error);
  }
}

export async function POST(url, body = null, headers = null) {
  try {
    return await axios.post(url, body, headers);
  } catch (error) {
    return await handleError(error);
  }
}

export async function PUT(url, body = null) {
  try {
    return await axios.put(url, body);
  } catch (error) {
    return await handleError(error);
  }
}

export async function PATCH(url, body = null) {
  try {
    return await axios.patch(url, body);
  } catch (error) {
    return await handleError(error);
  }
}

export async function DELETE(url, body = null) {
  try {
    return await axios.delete(url, { data: body });
  } catch (error) {
    return await handleError(error);
  }
}

export function handleError(error) {
  store.dispatch("fireError", error);
  return Promise.reject(error);
}

export default axios;
