import { GET, POST, PUT } from "@/config/backend";

export default {
  async getOptions(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.optionsConfig,
      { root: true }
    );

    const response = await GET(`/options`, { params });
    context.commit("setOptions", response.data.options.data);
    return response;
  },

  async save(context) {
    return await POST(`/options`, context.state.option);
  },

  async update(context) {
    const option = context.state.option;
    console.log("option", option);
    return await PUT(`/options/${option.id}`, option);
  },
};
