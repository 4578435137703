import { sanitizePayload } from "@/utils/payload";
import { GET, POST, DELETE, PUT, PATCH } from "@/config/backend";

function prepareParams(config) {
  return new Promise((resolve) => {
    let params = {};

    if (config.page) {
      params.page = config.page;
    }

    if (config.include) {
      params.include = config.include;
    }

    if (config.resource_fields) {
      params.resource_fields = config.resource_fields;
    }

    if (config.sort) {
      const sortField =
        config.sortDirection === "desc" ? `-${config.sort}` : config.sort;

      params.sort = sortField;
    }

    if (config.per_page) {
      params.per_page = config.per_page;
    }

    if (config.filters) {
      for (const [key, value] of Object.entries(config.filters)) {
        params[`filter[${key}]`] = value;
      }
    }

    resolve(params);
  });
}

export default {
  async createNewOrder({ commit, dispatch, state }) {
    const response = await POST(`orders`, state.newOrder);
    commit("setNewOrder", response.data.order);
    dispatch("createNewOrderOptions");

    return response;
  },

  async createNewOrderAdmin({ commit, dispatch, state }, userReference) {
    const response = await POST(
      `orders/create/${userReference}`,
      state.newOrder
    );
    commit("setNewOrder", response.data.order);
    dispatch("createNewOrderOptions");

    return response;
  },

  async editNewOrder({ commit, dispatch, state }) {
    const response = await PUT(
      `orders/${state.newOrder.reference}`,
      sanitizePayload(state.newOrder, state.payloadModels.update)
    );
    commit("setNewOrder", response.data.order);
    dispatch("createNewOrderOptions");

    return response;
  },

  async patchOrder({ state }, data) {
    return await PATCH(`orders/${state.newOrder.reference}/patch`, data);
  },

  orderPatchAction({ state, dispatch, commit }, { key }) {
    commit("setOrderBriefSaving", true);
    return dispatch("patchOrder", { [key]: state.newOrder[key] }).then(() => {
      commit("setOrderBriefSaving", false);
    });
  },

  async unaffectOrderWriter(context, data) {
    return await PUT(`orders/${data.order}/unaffect-writer/${data.writer}`);
  },

  async createDraftNewOrder({ commit, state }) {
    const response = await POST(`orders/save`, state.newOrder);
    commit("setNewOrder", response.data.order);

    return response;
  },

  async createDraftNewOrderAdmin({ commit, state }, userReference) {
    const response = await POST(`orders/save/${userReference}`, state.newOrder);
    commit("setNewOrder", response.data.order);

    return response;
  },

  async editDraftNewOrder({ commit, state }) {
    const response = await PUT(
      `orders/save/${state.newOrder.reference}`,
      state.newOrder
    );

    commit("setNewOrder", response.data.order);

    return response;
  },

  async editNewOrderAdmin({ commit, dispatch, state }, userReference) {
    const response = await PUT(
      `orders/create/${userReference}`,
      state.newOrder
    );

    commit("setNewOrder", response.data.order);
    dispatch("createNewOrderOptions");

    return response;
  },

  async getOrders({ state, commit }, configs = {}) {
    const ordersParams = configs.ordersConfig
      ? configs.ordersConfig
      : state.ordersConfig;

    const params = await prepareParams(ordersParams);
    const response = await GET(`orders`, { params });

    if (!configs.useLocalState) {
      commit("setOrders", response.data.orders);
    }

    return response;
  },

  async getOrdersCount({ commit }, orderParams) {
    const params = await prepareParams(orderParams);
    const response = await GET(`orders/count`, { params });

    commit("setOrdersCounts", {
      key: orderParams.key,
      value: response.data.orders_count,
    });

    return response;
  },

  async getOrdersByUser({ state, commit }, user_id) {
    const params = await prepareParams(state.ordersConfig);
    const response = await GET(`orders/orders-by-user/${user_id}`, { params });

    commit("setOrders", response.data.orders);

    return response;
  },

  async getOrder({ state, commit, dispatch }, reference) {
    const params = await prepareParams(state.orderConfig);
    const response = await GET(`orders/${reference}`, { params });
    commit("setOrder", response.data.order);

    if (response.data.order.options?.length > 0) {
      dispatch("createNewOrderOptions");
    }

    return response;
  },

  async getNewOrder({ state, commit, dispatch }, reference) {
    const params = await prepareParams(state.orderConfig);
    const response = await GET(`orders/${reference}`, { params });
    commit("setNewOrder", response.data.order);

    if (response.data.order.options?.length > 0) {
      dispatch("createNewOrderOptions");
    }

    return response;
  },

  async getNewOrderWithOptions({ state, commit }, reference) {
    const params = await prepareParams(state.orderConfig);
    const response = await GET(`orders/${reference}`, { params });
    commit("setNewOrder", response.data.order);

    if (response.data.order.company) {
      commit("company/setCompany", response.data.order.company, { root: true });
    }

    commit("website/setWebsiteOrderReference", response.data.order.reference, {
      root: true,
    });

    return response;
  },

  async getOptions(context) {
    const response = await GET(`/options`);
    context.commit("setOptions", response.data.options.data);

    return response;
  },

  // changement de statut
  async getUpdateStatus(context, reference) {
    return await GET(`/orders/${reference}/validate`);
  },

  async getCheckout(context, reference) {
    return await GET(`/orders/${reference}/checkout`);
  },

  async getDuplicateOrder(context, reference) {
    const response = await GET(`/orders/${reference}/duplicate`);
    context.commit("setNewOrder", response.data.order);

    return response;
  },

  async getOrderComments({ commit }, reference) {
    const response = await GET(`orders/${reference}/comments`);
    commit("setOrderComments", response.data.comments);

    return response;
  },

  async sendOrderComments(context, data) {
    return await POST(`orders/${data.reference}/comments`, data.comment);
  },

  async getOrderTracking(context, reference) {
    const response = await GET(`orders/${reference}/tracking`, {
      params: {
        resource_fields: {
          user: [
            "reference",
            "lastname",
            "firstname",
            "roles",
            "ordered_roles",
          ],
        },
      },
    });

    context.commit("setOrderTracking", response.data.tracking.data);

    return response;
  },

  async sendOrderEvaluate(context, reference) {
    return await PUT(`orders/${reference}/evaluate`);
  },

  createNewOrderOptions(context) {
    let options = [];

    if (context.state.newOrder?.options) {
      context.state.newOrder.options.forEach((element) => {
        if (element.id) {
          options.push(element.id);
        }
      });

      context.commit("setNewOrderOptions", options);
    }
  },

  async payOrderWithCredit(context, reference) {
    return await POST(`/orders/${reference}/pay-with-credit`);
  },

  async deleteOrder(context, reference) {
    return await DELETE(`orders/${reference}`);
  },

  async acceptOrderSubjects(context, reference) {
    return await PUT(`/orders/${reference}/subjects/accept`);
  },

  async rejectOrderSubjects(context, { reference, comment }) {
    return await PUT(`/orders/${reference}/subjects/reject`, {
      comment,
    });
  },
};
