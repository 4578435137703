<template>
  <router-link :to="link" class="is-fullheight">
    <button
      :class="`has-text-${color} is-inverted button is-${size} is-fullwidth has-border-${color} has-hover-bottom is-flex is-align-items-center`"
    >
      <component :is="icon" class="mr-2"></component>
      {{ text }}
    </button>
  </router-link>
</template>
<script>
export default {
  name: "ButtonRouterLink",
  props: {
    link: {},
    icon: {},
    color: {},
    text: {},
    size: {},
  },
};
</script>
