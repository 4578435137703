<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <b-field
        :custom-class="field.isUserView ? 'is-flex is-flex-grow-1 mr-2' : ''"
        :label="field.label ? field.label : ''"
        :class="{
          'is-danger': hasError,
          'is-align-items-center input-edit pt-2': isUserViewOrHasPatchAction,
          'is-flex': !field.isNoFlex,
        }"
        :message="hasError ? field.hasErrorMessage : ''"
      >
        <template #label>
          <div
            class="is-flex is-flex-gap-2 is-justify-content-space-between is-align-items-center"
          >
            <p v-if="field.label" class="body2 bold has-text-blue">
              {{ field.label }}
            </p>
            <div>
              <b-button
                v-if="field.isEditable && !field.isUserView"
                class="is-small has-text-blue has-border-none"
                @click.prevent="startEditing"
              >
                <IconPen class="iconPen" />
              </b-button>
            </div>
          </div>
        </template>

        <div
          :class="{
            'is-flex is-flex-gap-2 is-align-items-center is-flex-grow-5':
              isUserViewOrHasPatchAction,
            'is-flex-direction-column-reverse is-align-items-end':
              field.actionButtonInTop,
          }"
        >
          <vue-editor
            v-model="selected"
            disabled
            :value="selected"
            :editorOptions="editorOptions"
            :class="{
              'b-input mt-2': !isUserViewOrHasPatchAction,
              'width-100': isUserViewOrHasPatchAction,
            }"
            :placeholder="field.placeholder ? field.placeholder : ''"
          />

          <b-button
            v-if="field.isEditable && field.isUserView"
            class="is-small has-text-blue has-border-none"
            @click.prevent="startEditing"
          >
            <IconPen class="iconPen" />
          </b-button>
        </div>
      </b-field>

      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>

    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :custom-class="
        isUserViewOrHasPatchAction ? 'is-flex is-flex-grow-1 mr-2' : ''
      "
      :label="field.label ? field.label : ''"
      :class="{
        'is-danger': hasError,
        'is-align-items-center input-edit pt-2': isUserViewOrHasPatchAction,
        'is-flexx': !field.isNoFlex,
      }"
      :message="hasError ? field.hasErrorMessage : ''"
    >
      <div
        :class="{
          'is-flex is-flex-gap-2 is-align-items-center is-flex-grow-5':
            isUserViewOrHasPatchAction,
          'is-flex-direction-column-reverse is-align-items-end':
            field.actionButtonInTop,
        }"
      >
        <vue-editor
          v-model="selected"
          :value="selected"
          :class="{
            'b-input mt-2': !isUserViewOrHasPatchAction,
            'width-100': isUserViewOrHasPatchAction,
          }"
          :placeholder="field.placeholder ? field.placeholder : ''"
          :editorToolbar="editorToolBar"
        />

        <PartialSaveFormValueButton
          v-if="isUserViewOrHasPatchAction"
          :field="field"
          :callback="saveComponent"
          :before="persistValue"
        />
      </div>
    </b-field>

    <CardValidationField
      v-if="hasError && field.hasErrorMessage"
      :is-next-step="isNextStep"
      :message="field.hasErrorMessage"
    />
    <CardValidationField
      v-if="!selected || selected === ' '"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import { parsePlainTextUrlsToAnchorTags } from "@/utils/index";
import CardValidationField from "@/components/UI/card/CardValidationField";

import { VueEditor } from "vue2-editor";

export default {
  name: "BrieWysiwyg",
  props: {
    field: {
      type: Object,
      required: true,
    },
    indexStep: {},
    indexField: {},
    isNextStep: {
      type: Boolean,
      default: false,
    },
  },
  components: { CardValidationField, VueEditor },

  data() {
    return {
      isEditing: false,
      selected: this.field.value
        ? parsePlainTextUrlsToAnchorTags(this.field.value)
        : null,
      hasError: false,
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      editorToolBar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },

  computed: {
    isUserViewOrHasPatchAction() {
      return this.field.isUserView || this.field.patchAction;
    },
  },

  watch: {
    selected: function (newValue) {
      this.hasError = false;

      if (this.field.regex && !newValue.match(this.field.regex)) {
        this.hasError = true;
      }

      if (!this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          value: newValue ? parsePlainTextUrlsToAnchorTags(newValue) : null,
          hasError: this.hasError,
        });
      }
    },
  },

  methods: {
    saveComponent() {
      this.isEditing = false;

      if (this.field.isUserView) {
        this.persistValue();
      }
    },

    persistValue() {
      if (this.selected) {
        this.selected = parsePlainTextUrlsToAnchorTags(this.selected);
      }

      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        model: this.field.model,
        value: this.selected ? this.selected : null,
        hasError: this.hasError,
      });
    },

    startEditing() {
      this.isEditing = true;
    },
  },
};
</script>
<style>
.ql-container {
  background: white;
  color: #202560 !important;
  border-radius: 8px;
}

.ql-editor p {
  color: #202560 !important;
}

.candidate-form .quillWrapper {
  border-top: 1px solid #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.end {
  text-align: end !important;
}

.ql-disabled .ql-editor {
  padding: 0;
}

.ql-container.ql-snow.ql-disabled {
  border: none;
  margin-top: 10px;
}

.ql-editor[contenteditable="false"] {
  padding: 10px;
}

.ql-container.ql-snow:not(.ql-disabled) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ql-toolbar {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
</style>
