export default {
  isBeingEditedByAnotherUserOrSession(state) {
    return (
      !state.editingStatus.editing_by_authenticated_user ||
      state.editor?.readOnly?.isEnabled ||
      false
    );
  },

  checkEditingStatusCount(state) {
    return state.checkEditingStatusCount;
  },
};
