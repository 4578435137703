import { GET, POST, DELETE, PUT } from "@/config/backend";

export default {
  async getThematics(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.thematicsConfig,
      { root: true }
    );

    const response = await GET(`/thematics`, { params });
    context.commit("setThematics", response.data.thematics.data);
    context.dispatch("createCategoriesObject");
    return response;
  },

  async save(context) {
    return await POST(`/thematics`, context.state.thematic);
  },

  async update(context) {
    const thematic = context.state.thematic;
    return await PUT(`/thematics/${thematic.id}`, thematic);
  },

  async deleteThematic(context, id) {
    return await DELETE(`/thematics/${id}`);
  },

  createCategoriesObject(context) {
    let categories = {};
    let categorie = {};
    context.state.thematics.forEach((element) => {
      if (element.parent_id) {
        categories[element.parent_id] = [];
        categories[element.parent_id].id = element.parent_id;
        categorie = context.state.thematics.filter(
          (el) => el.id === element.parent_id
        );

        if (categorie.length > 0 && categorie[0].name) {
          categories[element.parent_id].name = categorie[0].name;
        }

        context.state.thematics.forEach((category) => {
          if (category.parent_id === element.parent_id) {
            categories[element.parent_id].push(category);
          }
        });
      }
    });
    context.commit("setCategories", categories);
  },
};
