export default () => ({
  transactions: null,
  transactionsConfig: {
    include: "",
    sort: "created_at",
    sortDirection: "desc",
    page: 1,
    filters: {},
    per_page: 100,
  },
});
