import IconAlertTriangle from "@/components/UI/icons/IconAlertTriangle";
import IconArrowGoBack from "@/components/UI/icons/IconArrowGoBack";
import IconArrowLeft from "@/components/UI/icons/IconArrowLeft";
import IconArrowRight from "@/components/UI/icons/IconArrowRight";
import IconBan from "@/components/UI/icons/IconBan";
import IconBook from "@/components/UI/icons/IconBook";
import IconCalendar from "@/components/UI/icons/IconCalendar";
import IconCheck from "@/components/UI/icons/IconCheck";
import IconChevronDown from "@/components/UI/icons/IconChevronDown";
import IconChevronUp from "@/components/UI/icons/IconChevronUp";
import IconCircle from "@/components/UI/icons/IconCircle";
import IconClock from "@/components/UI/icons/IconClock";
import IconClose from "@/components/UI/icons/IconClose";
import IconComment from "@/components/UI/icons/IconComment";
import IconCopy from "@/components/UI/icons/IconCopy";
import IconDelete from "@/components/UI/icons/IconDelete";
import IconDownload from "@/components/UI/icons/IconDownload";
import IconEdit from "@/components/UI/icons/IconEdit";
import IconExternalLink from "@/components/UI/icons/IconExternalLink";
import IconEye from "@/components/UI/icons/IconEye";
import IconFileText from "@/components/UI/icons/IconFileText";
import IconGlobe from "@/components/UI/icons/IconGlobe";
import IconHandsClapping from "@/components/UI/icons/IconHandsClapping";
import IconHashtag from "@/components/UI/icons/IconHashtag";
import IconHeart from "@/components/UI/icons/IconHeart";
import IconHelpQuestion from "@/components/UI/icons/IconHelpQuestion";
import IconInfo from "@/components/UI/icons/IconInfo";
import IconLoader from "@/components/UI/icons/IconLoader";
import IconMark from "@/components/UI/icons/IconMark";
import IconMoreHorizontal from "@/components/UI/icons/IconMoreHorizontal";
import IconOrder from "@/components/UI/icons/IconOrder";
import IconPen from "@/components/UI/icons/IconPen";
import IconPenLine from "@/components/UI/icons/IconPenLine";
import IconPlus from "@/components/UI/icons/IconPlus";
import IconSave from "@/components/UI/icons/IconSave";
import IconSearch from "@/components/UI/icons/IconSearch";
import IconShare from "@/components/UI/icons/IconShare";
import IconSend from "@/components/UI/icons/IconSend";
import IconUser from "@/components/UI/icons/IconUser";
import IconUserCheck from "@/components/UI/icons/IconUserCheck";
import IconVideo from "@/components/UI/icons/IconVideo";
import IconArrowExpand from "@/components/UI/icons/IconArrowExpand";
import IconFilter from "@/components/UI/icons/IconFilter";
import IconTimes from "@/components/UI/icons/IconTimes";

import PartialSaveFormValueButton from "@/components/Model/Form/PartialSaveFormValueButton";

const Components = {
  install(Vue) {
    Vue.component("IconAlertTriangle", IconAlertTriangle);
    Vue.component("IconArrowGoBack", IconArrowGoBack);
    Vue.component("IconArrowLeft", IconArrowLeft);
    Vue.component("IconArrowRight", IconArrowRight);
    Vue.component("IconBan", IconBan);
    Vue.component("IconBook", IconBook);
    Vue.component("IconCalendar", IconCalendar);
    Vue.component("IconCalendar", IconCalendar);
    Vue.component("IconCheck", IconCheck);
    Vue.component("IconChevronDown", IconChevronDown);
    Vue.component("IconChevronUp", IconChevronUp);
    Vue.component("IconCircle", IconCircle);
    Vue.component("IconClock", IconClock);
    Vue.component("IconClose", IconClose);
    Vue.component("IconComment", IconComment);
    Vue.component("IconCopy", IconCopy);
    Vue.component("IconDelete", IconDelete);
    Vue.component("IconDownload", IconDownload);
    Vue.component("IconEdit", IconEdit);
    Vue.component("IconExternalLink", IconExternalLink);
    Vue.component("IconEye", IconEye);
    Vue.component("IconFileText", IconFileText);
    Vue.component("IconGlobe", IconGlobe);
    Vue.component("IconHandsClapping", IconHandsClapping);
    Vue.component("IconHashtag", IconHashtag);
    Vue.component("IconHeart", IconHeart);
    Vue.component("IconHelpQuestion", IconHelpQuestion);
    Vue.component("IconInfo", IconInfo);
    Vue.component("IconLoader", IconLoader);
    Vue.component("IconMark", IconMark);
    Vue.component("IconMoreHorizontal", IconMoreHorizontal);
    Vue.component("IconOrder", IconOrder);
    Vue.component("IconPen", IconPen);
    Vue.component("IconPenLine", IconPenLine);
    Vue.component("IconPlus", IconPlus);
    Vue.component("IconSave", IconSave);
    Vue.component("IconSearch", IconSearch);
    Vue.component("IconShare", IconShare);
    Vue.component("IconSend", IconSend);
    Vue.component("IconUser", IconUser);
    Vue.component("IconUserCheck", IconUserCheck);
    Vue.component("IconVideo", IconVideo);
    Vue.component("IconArrowExpand", IconArrowExpand);
    Vue.component("IconFilter", IconFilter);
    Vue.component("IconTimes", IconTimes);

    Vue.component("PartialSaveFormValueButton", PartialSaveFormValueButton);
  },
};

export default Components;
