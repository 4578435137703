import Vue from "vue";
import VueRouter from "vue-router";
import AuthenticatedDashboard from "@/views/Auth/Dashboard.vue";
import siteRoutes from "@/router/site";
import orderRoutes from "@/router/order";
import textRoutes from "@/router/text";
import formRoutes from "@/router/form";
import userRoutes from "@/router/user";
import DashboardCustomer from "@/views/Dashboards/DashboardCustomer";
import DashboardAdmin from "@/views/Dashboards/DashboardAdmin";
import DashboardCandidate from "@/views/Dashboards/DashboardCandidate";
import DashboardEditorManager from "@/views/Dashboards/DashboardEditorManager";
import DashboardMain from "@/views/Dashboards/DashboardMain";
import DashboardWriter from "@/views/Dashboards/DashboardWriter";
import {
  adminMiddleware,
  editorManagerMiddleware,
  correctorMiddleware,
  writerMiddleware,
  customerMiddleware,
  candidateMiddleware,
  applyMiddlewareToRoutes,
} from "@/router/middleware";

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/reset",
    name: "ResetPwd",
    component: () => import("@/views/Auth/ResetPwd"),
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: () => import("@/views/Auth/SetPassword"),
  },
  {
    path: "/redirect-to-app",
    name: "RedirectToApp",
    component: () => import("@/views/Auth/RedirectToApp.vue"),
  },
  {
    path: "/inscription",
    name: "ClientRegistration",
    component: () => import("@/views/Auth/ClientRegistration.vue"),
  },
  {
    path: "/inscription/writer",
    name: "CandidateRegistration",
    component: () => import("@/views/Auth/CandidateRegistration.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/403.vue"),
  },
];

const routes = orderRoutes.concat(textRoutes, formRoutes, userRoutes);

const customerRoutes = [
  {
    path: "/customer/",
    component: AuthenticatedDashboard,
    children: routes.concat(
      siteRoutes,
      applyMiddlewareToRoutes(customerMiddleware, [
        {
          path: "",
          component: DashboardCustomer,
        },
        {
          path: "invoices",
          component: () => import("@/views/Invoice/Invoices.vue"),
        },
        {
          path: "invoices/:invoice_number",
          component: () => import("@/views/Invoice/InvoicesToPay.vue"),
        },
      ])
    ),
  },
];

const writerRoutes = [
  {
    path: "/writer/",
    component: AuthenticatedDashboard,
    children: routes.concat(
      applyMiddlewareToRoutes(writerMiddleware, [
        {
          path: "",
          component: DashboardWriter,
        },
        {
          path: "invoices",
          component: () => import("@/views/Invoice/InvoicesWriter.vue"),
        },
        {
          path: "website/:id/order/:reference/text/:key/edit",
          component: () => import("@/views/Text/TextEdit.vue"),
        },
      ])
    ),
  },
];

const editorRoutes = [
  {
    path: "/editor/",
    component: AuthenticatedDashboard,
    children: routes.concat(
      siteRoutes,
      applyMiddlewareToRoutes(editorManagerMiddleware, [
        {
          path: "",
          component: DashboardEditorManager,
        },
        {
          path: "new-order",
          component: () => import("@/views/Form/FormNewOrderAdmin.vue"),
        },
        {
          path: "internal/new-website",
          component: () => import("@/views/Form/FormNewWebsite.vue"),
        },
        {
          path: "user/:userReference/website/:id/new-order/:reference",
          component: () => import("@/views/Form/FormNewOrder.vue"),
        },
        {
          path: "user/:userReference/website/:id/new-order",
          component: () => import("@/views/Form/FormNewOrder.vue"),
        },
        {
          path: "user/:userReference/new-website",
          component: () => import("@/views/Form/FormNewWebsite.vue"),
        },
        {
          path: "user/:userReference/new-website/:id",
          component: () => import("@/views/Form/FormNewWebsite.vue"),
        },
        {
          path: "user/:userReference/new-website/:id/order/:reference",
          component: () => import("@/views/Form/FormNewWebsite.vue"),
        },
        {
          path: "website/:id/order/:reference/text/:key/edit",
          component: () => import("@/views/Text/TextEdit.vue"),
        },
        {
          path: "writers",
          component: () => import("@/views/User/ListWriters.vue"),
        },
        {
          path: "customers",
          component: () => import("@/views/User/ListCustomers.vue"),
        },
        {
          name: "DetailsCustomers",
          path: "customers/:reference",
          component: () => import("@/views/User/DetailsCustomer.vue"),
        },
        {
          name: "EditCustomers",
          path: "customers/:reference/edit",
          component: () => import("@/views/User/UserProfile.vue"),
        },
        {
          name: "DetailsWriter",
          path: "writers/:reference",
          component: () => import("@/views/User/DetailsWriter.vue"),
        },
        {
          name: "DetailsWriter",
          path: "writers/:reference/edit",
          component: () => import("@/views/User/UserProfile.vue"),
        },
      ])
    ),
  },
];

const candidateRoutes = [
  {
    path: "/candidate/",
    component: AuthenticatedDashboard,
    children: routes.concat(
      applyMiddlewareToRoutes(candidateMiddleware, [
        {
          path: "",
          component: DashboardCandidate,
        },
        {
          path: "website/:id/order/:reference/text/:key/edit",
          component: () => import("@/views/Text/TextEdit.vue"),
        },
      ])
    ),
  },
];

const correctorRoutes = [
  {
    path: "/corrector/",
    component: AuthenticatedDashboard,
    children: routes.concat(
      applyMiddlewareToRoutes(correctorMiddleware, [
        {
          path: "",
          component: DashboardMain,
        },
        {
          path: "website/:id/order/:reference/text/:key/edit",
          component: () => import("@/views/Text/TextEdit.vue"),
        },
      ])
    ),
  },
];

const adminRoutes = [
  {
    path: "/admin/",
    component: AuthenticatedDashboard,
    children: routes.concat(
      siteRoutes,
      applyMiddlewareToRoutes(adminMiddleware, [
        {
          path: "",
          component: DashboardAdmin,
        },
        {
          path: "new-order",
          component: () => import("@/views/Form/FormNewOrderAdmin.vue"),
        },
        {
          path: "user/:userReference/new-order",
          component: () => import("@/views/Form/FormNewWebsite.vue"),
        },
        {
          path: "monthly-invoices",
          name: "MonthlyInvoicesAdmin",
          component: () => import("@/views/Invoice/InvoicesMonthlyAdmin.vue"),
        },
        {
          path: "invoices",
          name: "InvoicesWritersAdmin",
          component: () => import("@/views/Invoice/InvoicesWritersAdmin.vue"),
        },
        {
          path: "website/:id/order/:reference/text/:key/edit",
          component: () => import("@/views/Text/TextEdit.vue"),
        },
      ])
    ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: baseRoutes.concat(
    candidateRoutes,
    customerRoutes,
    writerRoutes,
    correctorRoutes,
    editorRoutes,
    adminRoutes,
    [{ path: "*", redirect: "/404" }]
  ),
});

export default router;
