import { GET, POST, PUT, PATCH } from "@/config/backend";

export default {
  async getWriterPreference({ commit, dispatch }, userReference) {
    const response = await GET(`writer-preferences/${userReference}`);

    if (response.data) {
      commit("setWriterPreferences", response.data.preferences);
    }

    dispatch("createSubjectsIdArray");
    return response;
  },

  async createWriterPreference({ commit, state }) {
    const response = await POST(`writer-preferences`, state.writerPreferences);
    commit("setWriterPreferences", response.data.preferences);
    return response;
  },

  async editWriterPreference({ commit, state, dispatch }, reference) {
    const response = await PUT(
      `writer-preferences/${reference}`,
      state.writerPreferences
    );
    commit("setWriterPreferences", response.data.preferences);
    dispatch("createSubjectsIdArray");
    return response;
  },

  async patchWriterPreference({ state }, data) {
    return await PATCH(
      `writer-preferences/${state.writerPreferences.user_reference}/patch`,
      data
    );
  },

  writerPreferencePatchAction({ dispatch, state }, { key, value }) {
    return dispatch("patchWriterPreference", { [key]: value }).then(() => {
      if (key === "engagement") {
        dispatch(
          "writer/getWriterStats",
          state.writerPreferences.user_reference,
          { root: true }
        );
      }
    });
  },
};
