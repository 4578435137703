var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.avatar && _vm.isImageFound)?_c('figure',{staticClass:"image pr-2",class:{
    'is-96x96': _vm.size === 'big',
    'is-64x64': _vm.size === 'medium',
  }},[_c('img',{staticClass:"is-rounded rounded",class:{
      'has-border-blue': _vm.hasBorderBlue,
    },attrs:{"src":_vm.user.avatar_thumb_cropped || _vm.user.avatar,"alt":_vm.imgAlt,"title":_vm.imgAlt,"width":"auto","height":"auto"},on:{"error":_vm.handleImageError}})]):_c('figure',{staticClass:"image is-rounded rounded is-flex is-justify-content-center is-align-items-center",class:{
    'is-96x96 is-size-1': _vm.size === 'big',
    'is-64x64 is-size-3': _vm.size === 'medium',
    'has-border-blue': _vm.hasBorderBlue,
  }},[_c('IconUser',{staticClass:"has-fill-blue"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }