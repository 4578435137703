import { GET } from "@/config/backend";

export default {
  async getTransactions(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.transactionsConfig,
      { root: true }
    );
    const response = await GET(`/transactions`, { params });
    context.commit("setTransactions", response.data.transactions);
    return response;
  },
};
