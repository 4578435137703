export default {
  setActiveStep(state, step) {
    state.activeStep = step;
  },

  setSteps(state, steps) {
    state.steps = steps;
  },

  setStorageWebsiteId(state, steps) {
    state.storageWebsiteId = steps;
  },

  setStorageOrderRef(state, steps) {
    state.storageOrderRef = steps;
  },

  setIsPayment(state, steps) {
    state.isPayment = steps;
  },

  setIsSaving(state, status) {
    state.isSaving = status;
  },

  setIsLoadingCheckout(state, status) {
    state.isLoadingCheckout = status;
  },

  setIsSavingAndLoadingCheckout(state, status) {
    state.isSaving = status;
    state.isLoadingCheckout = status;
  },

  setIsModalPaymentWireTransfert(state, status) {
    state.isModalPaymentWireTransfert = status;
  },
};
