export default {
  setNewOrder(state, value) {
    state.newOrder = value;
  },
  setNewOrderOptions(state, value) {
    state.newOrder.options = value;
  },
  setOptions(state, value) {
    state.options = value;
  },
  setOrders(state, value) {
    state.orders = value;
  },
  setOrder(state, value) {
    state.order = value;
  },
  setOrdersConfig(state, value) {
    state.ordersConfig = value;
  },
  setOrderConfig(state, value) {
    state.orderConfig = value;
  },
  setOrderTracking(state, value) {
    state.orderTracking = value;
  },
  setOrderComments(state, value) {
    state.orderComments = value;
  },
  setOrderHasTemplate(state, value) {
    state.orderHasTemplate = value;
  },
  setActiveOrdersTab(state, value) {
    state.activeOrdersTab = value;
  },
  setOrdersCounts(state, value) {
    state.orderCounts[value.key] = value.value;

    localStorage.setItem("orderCounts", JSON.stringify(state.orderCounts));
  },
  resetOrderCounts(state) {
    localStorage.removeItem("orderCounts");
    Object.keys(state.orderCounts).forEach(
      (key) => (state.orderCounts[key] = "00")
    );
  },
  setOrderBriefSaving(state, value) {
    state.orderBriefSaving = value;
  },
  retrieveOrdersCountsFromLocalStorage(state) {
    const orderCounts = JSON.parse(localStorage.getItem("orderCounts"));

    if (orderCounts) {
      state.orderCounts = orderCounts;
    }
  },
  setNewOrderCommitmentValue(state, plan) {
    state.newOrder.subscription_type_id = plan.id;
    state.newOrder.subscription_duration = plan.iterations;
  },
  resetNewOrderCommitmentValue(state) {
    state.newOrder.subscription_type_id = null;
    state.newOrder.subscription_duration = null;
  },
  setOrderWithFailedPayment(state, value) {
    state.orderWithFailedPayment = value;
  },
};
