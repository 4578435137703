export default [
  {
    path: "website/:id/new-order/:reference/brief",
    component: () => import("@/views/Form/FormBrief.vue"),
  },
  {
    path: "website/:id/new-order",
    component: () => import("@/views/Form/FormNewOrder.vue"),
  },
  {
    path: "website/:id/new-order/:reference",
    component: () => import("@/views/Form/FormNewOrder.vue"),
  },
  {
    path: "new-website",
    component: () => import("@/views/Form/FormNewWebsite.vue"),
  },
  {
    path: "new-website/:id",
    component: () => import("@/views/Form/FormNewWebsite.vue"),
  },
  {
    path: "new-website/:id/order/:reference",
    component: () => import("@/views/Form/FormNewWebsite.vue"),
  },
  {
    path: "candidate",
    component: () => import("@/views/Form/FormNewCandidate.vue"),
  },
];
