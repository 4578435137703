<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value"
        @start-editing="isEditing = true"
      />
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :custom-class="field.isUserView && 'is-flex is-flex-grow-1 mr-2'"
      :label="field.label ? field.label : ''"
      :class="{
        'is-danger': hasError,
        'is-flex is-align-items-center input-edit': field.isUserView,
      }"
      :message="hasError ? field.hasErrorMessage : ''"
    >
      <div
        :class="{
          'is-flex is-align-items-center is-flex-grow-5': field.isUserView,
        }"
      >
        <vue-google-autocomplete
          id="map"
          v-model="selected"
          class="b-input mt-2 input"
          :class="{
            'b-input mt-2': !field.isUserView,
            'width-100': field.isUserView,
          }"
          placeholder=""
          v-on:placechanged="getAddressData"
        />

        <PartialSaveFormValueButton
          v-if="field.isEditable && field.isUserView"
          :field="field"
          :before="persistValue"
          :callback="stopEditing"
        />
      </div>
    </b-field>

    <CardValidationField
      v-if="!selected || selected === ' '"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "AddressAutocomplete",
  props: ["field", "indexStep", "indexField", "isNextStep"],
  components: { CardValidationField, OrderBriefArticle, VueGoogleAutocomplete },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : null,
      address: {},
      addressData: {},
      hasError: false,
    };
  },

  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
  },
  methods: {
    stopEditing() {
      this.isEditing = false;
    },

    saveComponent() {
      if (this.field.isUserView) {
        this.persistValue();
      }
    },

    getAddressData: function (addressData, newAddress) {
      this.address = newAddress;
      this.addressData = addressData;

      if (!this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          value: newAddress.formatted_address,
        });

        if (this.field.changeCityValue && addressData.locality) {
          this.$emit("save-result", {
            index: this.field.changeCityValue,
            index_field: this.field.changeCityValue,
            value_name: "city",
            value: addressData.locality,
          });
        }

        if (this.field.changeZipcodeValue && addressData.postal_code) {
          this.$emit("save-result", {
            index: this.field.changeZipcodeValue,
            index_field: this.field.changeZipcodeValue,
            value_name: "zipcode",
            value: addressData.postal_code,
          });
        }

        if (this.field.changeCountryValue && addressData.country) {
          this.$emit("save-result", {
            index: this.field.changeCountryValue,
            index_field: this.field.changeCountryValue,
            value_name: "country",
            value: addressData.country,
          });
        }
      }
    },

    persistValue() {
      if (this.address?.formatted_address) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          value: this.address.formatted_address,
          hasError: this.hasError,
          zipcode: this.addressData.postal_code,
          city: this.addressData.locality,
          model: this.field.model,
          additionalData: {
            zipcode: this.addressData.postal_code || null,
            city: this.addressData.locality || null,
          },
        });
      }
    },
  },
};
</script>
