export default {
  setCollaborators(state, value) {
    state.collaborators = value;
  },

  setCollaborator(state, value) {
    value.website_ids = [];

    if (value.websites) {
      value.website_ids = value.websites.map((website) => website.id);
    }

    state.collaborator = value;
  },

  setNewCollaborator(state, value) {
    state.newCollaborator = value;
  },

  setCollaboratorsConfig(state, value) {
    state.collaboratorsConfig = value;
  },

  setIsCollaboratorSend(state, data) {
    state.isCollaboratorSend = data;
  },
};
