export default {
  setInvoices(state, value) {
    state.invoices = value;
  },
  setInvoice(state, value) {
    state.invoice = value;
  },
  setMonthlyInvoices(state, value) {
    state.monthlyInvoices = value;
  },
  setUnpaidMonthlyInvoice(state, value) {
    state.unpaidMonthlyInvoice = value;
  },
  setInvoicesConfig(state, value) {
    state.invoicesConfig = value;
  },
  setOrderPaymentsConfig(state, value) {
    state.orderPaymentsConfig = value;
  },
  setOrderPayments(state, value) {
    state.orderPayments = value;
  },
};
