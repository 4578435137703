<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value"
        @start-editing="isEditing = true"
      />

      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>

    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :custom-class="
        isUserViewOrHasPatchAction && 'is-flex is-flex-grow-1 mr-2'
      "
      :class="{
        'is-danger': hasError,
        'is-flex is-align-items-center input-edit is-flex-gap-2':
          field.isUserView,
      }"
    >
      <template v-if="field.label" #label>
        {{ field.label }}
        <span v-if="field.required">*</span>
      </template>

      <div
        :class="{
          'is-flex is-align-items-center is-flex-grow-5':
            isUserViewOrHasPatchAction,
        }"
      >
        <b-input
          v-model="selected"
          :type="field.type || 'text'"
          :placeholder="field.placeholder ? field.placeholder : ''"
          :class="{
            'b-input mt-2': !isUserViewOrHasPatchAction,
            'width-100': isUserViewOrHasPatchAction,
          }"
          :disabled="isDisabled"
        />

        <PartialSaveFormValueButton
          v-if="isUserViewOrHasPatchAction"
          :field="field"
          :before="persistValue"
          :callback="stopEditing"
        />
      </div>
    </b-field>

    <CardValidationField
      v-if="(hasError || field.isValid === false) && field.hasErrorMessage"
      :is-next-step="isNextStep"
      :message="field.hasErrorMessage"
    />
    <CardValidationField
      v-else-if="isRequired"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "BriefText",
  props: ["field", "indexStep", "indexField", "isNextStep", "isDisabled"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : null,
      hasError: false,
    };
  },
  watch: {
    selected: function (newValue) {
      this.hasError = false;

      if (
        this.field.regex &&
        newValue &&
        !(newValue + "").match(this.field.regex)
      ) {
        this.hasError = true;
      }

      if (!this.field.isUserView) {
        this.persistValue(newValue);
      }
    },
  },

  computed: {
    isUserViewOrHasPatchAction() {
      return this.field.isUserView || this.field.patchAction;
    },
    isRequired() {
      if (!this.selected && this.field.dynamicRequired) {
        return this.field.dynamicRequired();
      }

      return !this.selected || !this.selected === " ";
    },
  },

  methods: {
    save() {
      this.persistValue();
    },

    stopEditing() {
      this.isEditing = false;
    },

    saveComponent() {
      if (this.field.isUserView) {
        this.persistValue();
      }
    },

    persistValue() {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: this.selected ? this.selected : null,
        hasError: this.hasError,
        model: this.field.model,
      });
    },
  },
};
</script>
