export default {
  setCompany(state, value) {
    state.company = value;
  },
  setNewCompany(state, value) {
    state.newCompany = value;
  },
  setCompanies(state, value) {
    state.companies = value;
  },
  setCompaniesConfig(state, value) {
    state.companiesConfig = value;
  },
  setCompanySearch(state, value) {
    state.companySearch = value;
  },
  setFormValue(state, { key, value, additionalData = {} }) {
    if (key === "address") {
      if (additionalData.city) {
        state.formValue["city"] = additionalData.city;
      }

      if (additionalData.zipcode) {
        state.formValue["zipcode"] = additionalData.zipcode;
      }
    }

    state.formValue[key] = value;
  },
};
