export default {
  setTemplates(state, value) {
    state.templates = value;
  },
  setCustomTemplates(state, value) {
    state.customTemplates = value;
  },
  addCustomTemplate(state, newCustomTemplate) {
    if (!Array.isArray(state.customTemplates)) {
      state.customTemplates = [];
    }

    if (
      !state.customTemplates.find(
        (template) => template.id === newCustomTemplate.id
      )
    ) {
      state.customTemplates.push(newCustomTemplate);
    }
  },
  editCustomTemplate(state, editedCustomTemplate) {
    if (!state.customTemplates) {
      return;
    }

    const index = state.customTemplates.findIndex(
      (template) => template.id === editedCustomTemplate.id
    );

    if (index !== -1) {
      state.customTemplates[index] = editedCustomTemplate;
    }
  },
  setTemplateSelected(state, value) {
    state.templateSelected = value;
  },
  setActiveTemplateData(state, value) {
    state.activeTemplateData = value;
  },
  resetActiveTemplateData(state) {
    state.activeTemplateData = {
      id: null,
      name: null,
      order_type_id: null,
      blocks: [],
    };
  },
  setTemplateConfig(state, value) {
    state.templateConfig = value;
  },
  setAvailableTemplateBlockOptions(state, value) {
    state.availableTemplateBlockOptions = value;
  },
  setPreserveTemplateState(state, value) {
    state.preserveTemplateState = value;
  },
};
