<template>
  <div id="app">
    <template v-if="isConnected">
      <header>
        <NavBar />
      </header>
      <router-view />
    </template>
    <component v-else :is="currentGuestComponent" />
  </div>
</template>

<script>
import NavBar from "@/components/UI/NavBar";
import LoginView from "@/views/Auth/Login";
import { mapState, mapGetters } from "vuex";
import ResetPwd from "@/views/Auth/ResetPwd";
import SetPassword from "@/views/Auth/SetPassword";
import ClientRegistration from "@/views/Auth/ClientRegistration";
import CandidateRegistration from "@/views/Auth/CandidateRegistration";
import RedirectToApp from "@/views/Auth/RedirectToApp";

export default {
  name: "App",
  components: {
    NavBar,
    ResetPwd,
    SetPassword,
    LoginView,
    ClientRegistration,
    CandidateRegistration,
    RedirectToApp,
  },
  computed: {
    ...mapState("auth", ["isConnected"]),
    ...mapGetters("auth", ["homepage"]),

    currentGuestComponent() {
      const guestRoutes = {
        "/reset": ResetPwd,
        "/set-password": SetPassword,
        "/inscription": ClientRegistration,
        "/inscription/writer": CandidateRegistration,
        "/redirect-to-app": RedirectToApp,
      };

      return guestRoutes[this.$route.path] || LoginView;
    },
  },
  created() {
    const currentUrl = window.location.pathname;

    if (currentUrl === "/" && this.isConnected) {
      this.$router.push({ path: this.homepage });
    }
  },
};
</script>
<style scoped>
#app {
  height: 100%;
  width: 100%;
}
</style>
