export default () => ({
  collaborators: null,
  collaborator: {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    country_code: "FR",
    websites: [],
    website_ids: [],
  },
  newCollaborator: {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    country_code: "FR",
    website_ids: [],
  },
  collaboratorsConfig: {
    include: "",
    sort: "created_at",
    sortDirection: "desc",
    page: 1,
    filters: {},
    per_page: 100,
  },
  isCollaboratorSend: false,
});
