<template>
  <div>
    <article
      v-if="field.isEditableFormat && !isEditing"
      class="is-flex is-flex-direction-column"
    >
      <div
        class="is-flex is-align-items-center is-justify-content-space-between"
      >
        <p class="body2 bold has-text-blue mb-2">
          {{ field.label }}
        </p>
        <button
          v-if="field.isEditable"
          class="button is-small has-text-blue has-border-none has-background-white my-1"
          @click.prevent="isEditing = true"
        >
          <IconPen class="iconPen my-1" />
        </button>
      </div>
      <div v-if="result && result.length > 0">
        <div
          v-for="(file, index) in result"
          :key="index"
          class="card is-flex has-text-blue bold is-justify-content-space-between py-2 my-1 has-border-none"
        >
          <div class="pl-2" v-if="file.file_name">
            <a :href="file.original_url" :download="file.file_name">
              {{ file.file_name }}
              <p class="body2 text-blue-light">{{ file.size / 100000 }}</p>
            </a>
          </div>
          <div class="pl-2" v-else-if="file.name">
            <a :href="file.encodedFile" :download="file.name">
              {{ file.name }}
            </a>
          </div>
        </div>
      </div>
      <div v-else-if="defaultFiles">
        <p v-for="(file, index) in defaultFiles" :key="index" class="mb-1">
          <a
            class="body2 text-blue-light is-underlined"
            target="_blank"
            :href="file.original_url"
            :download="file.file_name"
          >
            {{ file.file_name }}
          </a>
        </p>
      </div>
      <div v-else>
        <p class="body2 text-blue-light">{{ $t("forms.brief.upload.null") }}</p>
      </div>
    </article>
    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <div
        v-if="field.value_name === 'avatar' && field.avatar"
        class="is-flex columns p-2 is-flex-wrap-wrap is-align-items-center"
      >
        <figure
          v-if="field.value_name === 'avatar' && field.avatar"
          class="image pr-2 is-64x64"
        >
          <img
            class="is-rounded rounded"
            height="auto"
            width="auto"
            :src="field.avatar"
          />
        </figure>
        <ButtonIcon
          v-if="field.totot"
          color="blue"
          size="small"
          icon="IconDelete"
          @click-button="removeAvatar"
        />
      </div>

      <b-field>
        <template v-if="field.label" #label>
          {{ field.label }}
          <span v-if="field.required">*</span>
        </template>
        <div class="width-100">
          <b-upload
            v-model="dropFiles"
            multiple
            drag-drop
            expanded
            class="has-background-white radius"
            :disabled="disabled || loading"
            v-bind="acceptAttr ? { accept: acceptAttr } : {}"
          >
            <div
              class="has-text-centered is-flex is-flex-direction-column is-align-items-center is-justify-content-space-evenly"
            >
              <figure class="image has-text-pink py-2">
                <img
                  src="/img/svg/file_import.svg"
                  :title="$t('forms.brief.upload.image_alt')"
                  :alt="$t('forms.brief.upload.image_alt')"
                />
              </figure>
              <div
                class="button is-small is-pink is-inverted has-border-pink has-hover-bottom py-2"
              >
                {{ $t("forms.brief.upload.import") }}
              </div>
              <p class="body2 py-2">{{ $t("forms.brief.upload.drag_drop") }}</p>
              <p v-if="field.maxSize" class="body2">
                {{
                  $t("forms.brief.upload.max_size", {
                    maxSize: field.maxSize,
                  })
                }}
              </p>
            </div>
          </b-upload>

          <div
            v-for="(file, index) in result"
            :key="index"
            class="card is-flex is-flex-gap-2 has-text-blue bold is-align-items-center is-justify-content-space-between py-2 my-1 has-border-none"
          >
            <div class="pl-2" v-if="file.file_name">
              <a :href="file.original_url" :download="file.file_name">
                {{ file.file_name }}
                <p class="body2 text-blue-light">{{ file.size / 100000 }}</p>
              </a>
            </div>
            <div class="pl-2" v-else>
              {{ file.name }}
              <p class="body2 text-blue-light">{{ file.size }}</p>
            </div>
            <b-button
              class="is-large has-text-blue has-border-none"
              icon-pack="fas"
              icon-right="trash-can"
              :disabled="loading"
              @click="deleteDropFile(index)"
            />
          </div>
        </div>

        <PartialSaveFormValueButton :field="field" :callback="stopEditing" />
      </b-field>
    </div>

    <CardValidationField
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
    <CardValidationField
      :field="isValidAcceptedExt"
      :is-next-step="true"
      :message="$t('forms.brief.upload.format') + `: ${field.acceptedExt}`"
    />
    <CardValidationField
      v-if="isNumberMaxExceeded"
      :field="false"
      :is-next-step="true"
      :message="$t('forms.brief.upload.max_file') + `${field.max || 1}`"
    />
    <CardValidationField
      v-if="isMaxSizeExceeded"
      :field="false"
      :is-next-step="true"
      :message="
        $t('forms.brief.upload.max_file_size', { maxSize: field.maxSize })
      "
    />
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";
export default {
  name: "brief-upload",
  props: {
    field: {
      type: Object,
      required: true,
    },
    indexStep: {
      type: Number,
    },
    indexField: {
      type: Number,
    },
    isNextStep: {},
    isNotWizard: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: { CardValidationField, ButtonIcon },

  data() {
    return {
      isEditing: false,
      result: [],
      dropFiles: [],
      maxSize: 2,
      isValidAcceptedExt: true,
      isNumberMaxExceeded: false,
      isMaxSizeExceeded: false,
    };
  },

  created() {
    if (!this.isNotWizard) {
      let registeredFiles =
        this.$store.state.wizard.steps[this.indexStep]?.content[this.indexField]
          ?.value;

      if (!registeredFiles && Array.isArray(this.field.value)) {
        registeredFiles = this.field.value;
      } else {
        return;
      }

      Object.values(registeredFiles).forEach((element) => {
        this.result.push(element);
      });
    }

    if (this.field.initValue) {
      this.result = this.field.initValue() || [];
    }
  },

  methods: {
    deleteDropFile(index) {
      this.result.splice(index, 1);

      if (this.dropFiles?.length) {
        this.dropFiles.splice(index, 1);
      }

      this.isNumberMaxExceeded = false;
      this.isMaxSizeExceeded = false;
    },

    encodeFileToBase64(file) {
      this.isValidAcceptedExt = true;

      let fr = new FileReader();

      fr.onload = (e) => {
        this.result.push({
          name: file.name,
          ext: file.name.split(".").pop(),
          encodedFile: e.target.result,
        });
      };

      fr.readAsDataURL(file);
    },

    removeAvatar() {
      this.$emit("remove-avatar");
    },

    isSingleFileExceededValidation(fileList) {
      if ((this.field.max === 1 || !this.multiple) && fileList.length > 1) {
        this.dropFiles = [fileList[fileList.length - 1]];
        return true;
      }

      return false;
    },

    isMaxFilesExceededValidation(fileList) {
      if (
        (this.field.max && fileList.length > this.field.max) ||
        this.result.length >= this.field.max ||
        (!this.multiple && (this.result.length || fileList.length > 1))
      ) {
        this.isNumberMaxExceeded = true;
        return true;
      }

      return false;
    },

    isMaxSizeExceededValidation(fileList) {
      if (this.field.maxSize && fileList.length > 0) {
        for (let file of fileList) {
          if (file.size > this.field.maxSize * 1000000) {
            this.isMaxSizeExceeded = true;
            return true;
          }
        }
      }

      return false;
    },

    removeInvalidFile(file) {
      this.isValidAcceptedExt = false;
      let index = this.dropFiles.findIndex(
        (element) => element.name === file.name
      );
      this.dropFiles.splice(index, 1);
    },

    stopEditing() {
      this.isEditing = false;
    },
  },

  watch: {
    result: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
        initValue: newValue,
      });
    },

    dropFiles: function (newValue) {
      this.isNumberMaxExceeded = false;
      this.isMaxSizeExceeded = false;

      if (
        this.isSingleFileExceededValidation(newValue) ||
        this.isMaxFilesExceededValidation(newValue) ||
        this.isMaxSizeExceededValidation(newValue)
      ) {
        return;
      }

      newValue.forEach((file) => {
        let fileExt = file.name.split(".").pop();
        let acceptedExt = this.field.acceptedExt;

        if (acceptedExt && !acceptedExt.includes(fileExt)) {
          this.removeInvalidFile(file);
        } else if (
          this.result.filter((el) => el.name === file.name).length === 0
        ) {
          this.encodeFileToBase64(file);
        }

        this.dropFiles = [];
      });
    },
  },

  computed: {
    userProfile: {
      get() {
        return this.$store.state.user.userProfile;
      },
      set(value) {
        this.$store.commit("user/setUserProfile", value);
      },
    },

    acceptAttr() {
      if (this.field.acceptedExt) {
        return this.field.acceptedExt.map((ext) => `.${ext}`).join(", ");
      }

      return null;
    },

    defaultFiles() {
      if (
        this.field.value &&
        typeof this.field.value === "object" &&
        !Array.isArray(this.field.value)
      ) {
        return Object.values(this.field.value).map((file) => ({
          file_name: file.file_name,
          original_url: file.original_url,
        }));
      }

      return null;
    },
  },
};
</script>
