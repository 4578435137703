export default {
  setYtgGuide(state, value) {
    state.ytgGuide = value;
  },
  setYtgRecWords(state, value) {
    state.ytgRecWords = value;
  },
  setYtgCompWords(state, value) {
    state.ytgCompWords = value;
  },
  setYtgWordsCheck(state, value) {
    state.ytgWordsCheck = value;
  },
  setYtgWordsScores(state, value) {
    state.ytgWordsScores = value;
  },
  setYtgSerp(state, value) {
    state.ytgSerp = value;
  },
  setYtgDSEO(state, value) {
    state.ytgDseo = value;
  },
  setYtgSOSEO(state, value) {
    state.ytgSoseo = value;
  },
  setIsYtgGuideLaunch(state, value) {
    state.isYtgGuideLaunch = value;
  },
  setYtgGuideId(state, value) {
    state.ytgGuideId = value;
  },
  setYtgSOSEOMinRef(state, value) {
    state.ytgSOSEOMinRef = value;
  },
  setYtgSOSEOMin(state, value) {
    state.ytgSOSEOMin = value;
  },
  setYtgDSEOMax(state, value) {
    state.ytgDSEOMax = value;
  },
};
