export default {
  setUsers(state, value) {
    state.users = value;
  },
  setUsersCollection(state, value) {
    state.usersCollection = value;
  },
  setUser(state, value) {
    state.user = value;

    if (state.user && state.user.roles) {
      let roles = [];
      state.user.roles.forEach((element) => {
        element.name ? roles.push(element.name) : null;
      });
      state.user.roles = roles;
    }
  },
  setNewUser(state, value) {
    state.newUser = value;
  },
  setUsersConfig(state, value) {
    state.usersConfig = value;
  },
  setUserProfile(state, data) {
    state.userProfile = data;
  },
  setCandidateProfile(state, data) {
    state.candidateProfile = data;
  },
  setIsUserSend(state, data) {
    state.isUserSend = data;
  },
  setPasswordGenerated(state, data) {
    state.passwordGenerated = data;
  },
  setBillingPortalUrl(state, data) {
    state.billingPortalUrl = data;
  },
  setOnGoingSubscriptionCount(state, data) {
    state.onGoingSubscriptionCount = data;
  },
  setFormValue(state, { key, value, additionalData = {} }) {
    if (key === "phone") {
      if (additionalData.country_code) {
        state.formValue["country_code"] = additionalData.country_code;
      }
    }

    state.formValue[key] = value;
  },
};
