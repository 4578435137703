import { POST, DELETE } from "@/config/backend";
import { v4 as uuidv4 } from "uuid";

export default {
  checkEditingStatus(context, reference) {
    let tabSessionUuid = sessionStorage.getItem("tabSessionUuid");

    if (!tabSessionUuid) {
      tabSessionUuid = uuidv4();
      sessionStorage.setItem("tabSessionUuid", tabSessionUuid);
    }

    return POST(`texts/${reference}/editing-status`, {
      session_uuid: tabSessionUuid,
    }).then((response) => {
      context.commit("incrementCheckEditingStatusCount");
      context.commit("setEditingStatus", response.data);
      context.commit(
        "setEditorReadOnlyStatus",
        !response.data.editing_by_authenticated_user ||
          (response.data.editing_by_authenticated_user &&
            !response.data.editing_in_same_session_user)
      );
      return response.data;
    });
  },

  removeEditingStatus({ commit }, { reference, forceReset }) {
    return DELETE(
      `texts/${reference}/editing-status`,
      forceReset ? { forceReset } : null
    ).then(() => {
      commit("resetEditingStatus");
    });
  },
};
