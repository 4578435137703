export const prepareTextsUploadValue = (texts) => {
  const textsUpload = [];

  if (texts && typeof texts === "object") {
    for (const textKey of Object.keys(texts)) {
      const text = texts[textKey];

      textsUpload.push({
        uuid: text.uuid,
        ext: text.extension,
        name: text.file_name,
      });
    }
  }

  return textsUpload;
};

export const prepareAvatarUploadValue = (avatar) => {
  if (!avatar) {
    return [];
  }

  const ext = avatar.split(".").pop();
  const name = avatar.split("/").pop();

  return [
    {
      ext,
      name,
    },
  ];
};

export const prepareSubjectsValue = (subjects) => {
  if (Array.isArray(subjects)) {
    return subjects.map((subject) => {
      return typeof subject === "object" ? subject.id : subject;
    });
  }

  if (subjects) {
    return subjects;
  }

  return [];
};
