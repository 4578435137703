import { GET, POST, PUT } from "@/config/backend";

function prepareParams(config) {
  return new Promise((resolve) => {
    let field = config.sort;

    if (config.sortDirection === "desc") {
      field = "-" + field;
    }

    let params = {
      page: config.page,
      include: config.include,
      with: config.with,
      sort: field,
      per_page: config.per_page,
    };

    if (config.filters) {
      for (const [key, value] of Object.entries(config.filters)) {
        params[`filter[${key}]`] = value;
      }
    }

    resolve(params);
  });
}

export default {
  async getTemplates({ state, commit }) {
    const params = await prepareParams(state.templateConfig);
    const response = await GET(`/templates`, { params });

    state.templateConfig.filters?.is_public === 1 ||
    state.templateConfig.filters?.editable
      ? commit("setTemplates", response.data.templates)
      : commit("setCustomTemplates", response.data.templates);

    return response;
  },

  async getTemplate({ state, commit }, id) {
    const params = await prepareParams(state.templateConfig);
    const response = await GET(`/templates/${id}`, { params });

    commit("setTemplates", response.data.template);

    return response;
  },

  async getOrderTypeTemplateBlocks(context, orderType) {
    let endpoint = "/blocks";

    if (orderType) {
      endpoint += `/${orderType}`;
    }

    const response = await GET(endpoint);

    context.commit("setAvailableTemplateBlockOptions", response.data.blocks);

    return response;
  },

  async createNewTemplate({ commit, state }) {
    const response = await POST(`templates`, state.activeTemplateData);
    commit("setActiveTemplateData", response.data.template);
    commit("addCustomTemplate", response.data.template);
    return response;
  },

  async editTemplate({ commit, state }) {
    const response = await PUT(
      `templates/${state.activeTemplateData.id}`,
      state.activeTemplateData
    );
    commit("setActiveTemplateData", response.data.template);
    commit("editCustomTemplate", response.data.template);
    return response;
  },
  async updateTemplate(context, { templateId, data }) {
    return await PUT(`templates/${templateId}`, data);
  },
  async createTemplate(context, data) {
    return await POST(`templates`, data);
  },
};
