var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.field.isEditableFormat && !_vm.isEditing)?_c('div',[_c('b-field',{class:{
        'is-danger': _vm.hasError,
        'is-align-items-center input-edit pt-2': _vm.isUserViewOrHasPatchAction,
        'is-flex': !_vm.field.isNoFlex,
      },attrs:{"custom-class":_vm.field.isUserView ? 'is-flex is-flex-grow-1 mr-2' : '',"label":_vm.field.label ? _vm.field.label : '',"message":_vm.hasError ? _vm.field.hasErrorMessage : ''},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"is-flex is-flex-gap-2 is-justify-content-space-between is-align-items-center"},[(_vm.field.label)?_c('p',{staticClass:"body2 bold has-text-blue"},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]):_vm._e(),_c('div',[(_vm.field.isEditable && !_vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1)])]},proxy:true}],null,false,2641620797)},[_c('div',{class:{
          'is-flex is-flex-gap-2 is-align-items-center is-flex-grow-5':
            _vm.isUserViewOrHasPatchAction,
          'is-flex-direction-column-reverse is-align-items-end':
            _vm.field.actionButtonInTop,
        }},[_c('vue-editor',{class:{
            'b-input mt-2': !_vm.isUserViewOrHasPatchAction,
            'width-100': _vm.isUserViewOrHasPatchAction,
          },attrs:{"disabled":"","value":_vm.selected,"editorOptions":_vm.editorOptions,"placeholder":_vm.field.placeholder ? _vm.field.placeholder : ''},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.field.isEditable && _vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1)]),_c('CardValidationField',{attrs:{"field":_vm.field.isValid,"is-next-step":_vm.isNextStep,"message":_vm.$t('wizard.message.required')}})],1):_vm._e(),(!_vm.field.isEditableFormat || (_vm.field.isEditableFormat && _vm.isEditing))?_c('b-field',{class:{
      'is-danger': _vm.hasError,
      'is-align-items-center input-edit pt-2': _vm.isUserViewOrHasPatchAction,
      'is-flexx': !_vm.field.isNoFlex,
    },attrs:{"custom-class":_vm.isUserViewOrHasPatchAction ? 'is-flex is-flex-grow-1 mr-2' : '',"label":_vm.field.label ? _vm.field.label : '',"message":_vm.hasError ? _vm.field.hasErrorMessage : ''}},[_c('div',{class:{
        'is-flex is-flex-gap-2 is-align-items-center is-flex-grow-5':
          _vm.isUserViewOrHasPatchAction,
        'is-flex-direction-column-reverse is-align-items-end':
          _vm.field.actionButtonInTop,
      }},[_c('vue-editor',{class:{
          'b-input mt-2': !_vm.isUserViewOrHasPatchAction,
          'width-100': _vm.isUserViewOrHasPatchAction,
        },attrs:{"value":_vm.selected,"placeholder":_vm.field.placeholder ? _vm.field.placeholder : '',"editorToolbar":_vm.editorToolBar},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.isUserViewOrHasPatchAction)?_c('PartialSaveFormValueButton',{attrs:{"field":_vm.field,"callback":_vm.saveComponent,"before":_vm.persistValue}}):_vm._e()],1)]):_vm._e(),(_vm.hasError && _vm.field.hasErrorMessage)?_c('CardValidationField',{attrs:{"is-next-step":_vm.isNextStep,"message":_vm.field.hasErrorMessage}}):_vm._e(),(!_vm.selected || _vm.selected === ' ')?_c('CardValidationField',{attrs:{"field":_vm.field.isValid,"is-next-step":_vm.isNextStep,"message":_vm.$t('wizard.message.required')}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }