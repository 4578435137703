export default {
  setWebSite(state, value) {
    state.website = value;
  },

  setWebSites(state, value) {
    state.websites = value;
  },

  setAllWebSites(state, value) {
    state.allWebsites = value;
  },

  setwebsitesConfig(state, value) {
    state.websitesConfig = value;
  },

  setWebsiteOrderReference(state, value) {
    state.website.order_reference = value;
  },
};
