export default [
  {
    path: "website/:id/order/:reference/texts",
    component: () => import("@/views/Order/OrderTexts.vue"),
  },
  {
    path: "website/:id/order/:reference",
    redirect: "website/:id/order/:reference/texts",
  },
  {
    path: "website/:id/order/:reference/tracking",
    component: () => import("@/views/Order/OrderTracking.vue"),
  },
  {
    path: "website/:id/order/:reference/brief",
    component: () => import("@/views/Order/OrderBrief.vue"),
  },
  {
    path: "website/:id/order/:reference/writers",
    component: () => import("@/views/Order/OrderWritersAttribute.vue"),
  },
  {
    path: "website/:id/order/:reference/validated-texts",
    component: () => import("@/views/Order/OrderWebsiteValidatedTexts.vue"),
  },
  {
    path: "order/:reference/success",
    component: () => import("@/views/Order/OrderPaymentSuccess.vue"),
  },
  {
    path: "order/:reference/cancel",
    component: () => import("@/views/Order/OrderPaymentCancel.vue"),
  },
];
