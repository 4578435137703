export default () => ({
  editor: null,
  isContentSaving: false,
  checkEditingStatusCount: 0,
  editingStatus: {
    under_editing: false,
    editing_by_authenticated_user: true,
    editing_in_same_device: true,
    editing_in_same_session_user: true,
  },
});
