import { GET } from "@/config/backend";

export default {
  async getRoles(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.rolesConfig,
      { root: true }
    );
    const response = await GET(`/roles`, { params });
    context.commit("setRoles", response.data.roles);
    return response;
  },
};
