export default () => ({
  templates: null,
  customTemplates: null,
  templatesBlocks: null,
  templateSelected: {
    template_type: null,
    template_cocoon_selected: null,
    template_custom_selected: null,
  },
  activeTemplateData: { id: null, name: null, blocks: null },
  availableTemplateBlockOptions: null,
  templateConfig: {
    filters: {},
  },
  preserveTemplateState: false,
});
