<template>
  <article
    v-if="isNextStep && !field"
    class="card is-flex is-align-items-center has-border-none has-background-red-light py-2 my-2 card-validation-error"
  >
    <div class="card-content has-text-red pr-0 is-flex is-align-items-center">
      <iconAlertTriangle class="iconAlertTriangle is-color-inverted" />
    </div>
    <div class="card-content is-flex is-align-items-center">
      <p class="body1 has-text-red jagged-bottom">{{ message }}</p>
    </div>
  </article>
</template>
<script>
export default {
  name: "CardValidationField",
  props: {
    field: {},
    isNextStep: {},
    message: {},
  },
};
</script>

<style scoped>
.iconAlertTriangle {
  width: 1.5em !important;
  height: 1.5em !important;
}
</style>
