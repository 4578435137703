import { sanitizePayload } from "@/utils/payload";
import { GET, POST, PUT, PATCH } from "@/config/backend";

export default {
  async getCompanies(context) {
    let params = await context.dispatch(
      "prepareParams",
      context.state.companiesConfig,
      { root: true }
    );

    const response = await GET(`companies`, { params });
    context.commit("setCompanies", response.data.companies);
    return response;
  },

  async getCompany({ commit }, reference) {
    const response = await GET(`companies/${reference}`);
    commit("setCompany", response.data.company);
    return response;
  },

  async checkVat(context, vat) {
    const response = await GET(`companies/check-vat/${vat}`);
    return response.data.vat;
  },

  async addCompany({ commit, state }) {
    const response = await POST(`companies`, state.newCompany);
    commit("setCompany", response.data.company);
    return response;
  },

  async editCompany({ commit, state }, reference) {
    const response = await PUT(
      `companies/${reference}`,
      sanitizePayload(state.company, state.payloadModels.update)
    );
    commit("setCompany", response.data.company);
    return response;
  },

  async patchCompany({ state }, data) {
    return await PATCH(`companies/${state.company.reference}/patch`, data);
  },

  companyPatchAction(
    { dispatch, commit },
    { key, value, additionalData = {} }
  ) {
    commit("order/setOrderBriefSaving", true, { root: true });
    return dispatch("patchCompany", { [key]: value, ...additionalData }).then(
      () => {
        commit("order/setOrderBriefSaving", false, { root: true });
      }
    );
  },

  async updateCompanyInternalComment(context, { reference, internal_comment }) {
    return await PUT(`companies/${reference}/update-internal-comment`, {
      internal_comment,
    });
  },
};
