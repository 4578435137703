<template>
  <button
    class="button ml-auto inline-flex items-center has-text-red is-size-4"
    @click.prevent="$emit('click')"
  >
    <IconClose class="is-color-inverted" />
  </button>
</template>
<script>
export default {
  name: "CloseModalButton",
};
</script>
