<template>
  <div>
    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :label="field.label ? field.label : ''"
      :class="{ 'is-danger': hasError }"
      :message="hasError ? field.hasErrorMessage : ''"
    >
      <b-input
        v-model="selected"
        type="password"
        :placeholder="field.placeholder ? field.placeholder : ''"
        class="b-input mt-2"
      />
    </b-field>
    <CardValidationField
      v-if="hasError && field.hasErrorMessage"
      :is-next-step="isNextStep"
      :message="field.hasErrorMessage"
    />
    <CardValidationField
      v-if="(!selected || selected === '') && required"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";

export default {
  name: "BriefPassword",
  props: ["field", "indexStep", "indexField", "isNextStep"],
  components: { CardValidationField },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : null,
      hasError: false,
      required: this.field.required ? this.field.required : false,
    };
  },
  watch: {
    selected: function (newValue) {
      this.hasError = false;

      if (this.field.regex && !newValue.match(this.field.regex)) {
        this.hasError = true;
      }

      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue ? newValue : null,
        hasError: this.hasError,
      });
    },
  },
};
</script>
