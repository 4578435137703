export default {
  setRanxplorerRelatedKeywords(state, value) {
    state.ranxplorerRelatedKeywords = value;
  },
  resetRanxplorerRelatedKeywords(state) {
    state.ranxplorerRelatedKeywords = null;
  },
  setRanxplorerRelatedQuestions(state, value) {
    state.ranxplorerRelatedQuestions = value;
  },
  resetRanxplorerRelatedQuestions(state) {
    state.ranxplorerRelatedQuestions = null;
  },
};
