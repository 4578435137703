export default {
  setBalance(state, value) {
    state.balance = value;
  },

  setCreditHistories(state, value) {
    state.creditHistories = value;
  },

  setCreditHistoriesConfig(state, value) {
    state.creditHistoriesConfig = value;
  },
};
